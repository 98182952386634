import {
  FC,
  PropsWithChildren,
  useEffect,
  useRef
} from 'react';
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  useSelector
} from 'react-redux';
import {
  selectIsAuthenticated
} from '@entities/session';
import {
  selectIsOpenConfirmationSignUpModal,
  selectIsOpenSuccessConfirmationSignUpModal,
  useSignUpSelector
} from '@features/sign-up';

export const RedirectIfNotAuthorized: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const previousSearch = useRef(location.search);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isOpenConfirmationSignUpModal = useSignUpSelector(selectIsOpenConfirmationSignUpModal);
  const isOpenSuccessConfirmationSignUpModal = useSignUpSelector(selectIsOpenSuccessConfirmationSignUpModal);

  const allowToRedirect =
    !isAuthenticated &&
    !isOpenConfirmationSignUpModal &&
    !isOpenSuccessConfirmationSignUpModal;

  useEffect(() => {
    const hasQueryParams = location.search.length > 0;
    const hadQueryParams = previousSearch.current.length > 0;

    if (allowToRedirect && hadQueryParams && !hasQueryParams) {
      Cookies.remove('authToken', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      window.location.href = process.env.REACT_APP_SSR_CLIENT!;
    }

    if (allowToRedirect && !hasQueryParams) {
      Cookies.remove('authToken', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      window.location.href = process.env.REACT_APP_SSR_CLIENT!;
    }

    previousSearch.current = location.search;
  }, [allowToRedirect, location.search, navigate]);

  return children;
};
