import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CheckTrackingCodesState } from './types';

const initialState: CheckTrackingCodesState = {
  codes: null,
};

export const checkTrackingCodesSlice = createSlice({
  name: 'check-tracking-codes',
  initialState,
  reducers: {
    setTrackingCodes: (state: CheckTrackingCodesState, action: PayloadAction<string>) => {
      state.codes = action.payload;
    },
    clearTrackingCodes: (state: CheckTrackingCodesState, action: PayloadAction<string>) => {
      state.codes = null;
    },
  },
});

export const checkTrackingCodesReducer = checkTrackingCodesSlice.reducer;

export const { setTrackingCodes, clearTrackingCodes } = checkTrackingCodesSlice.actions;
