import { baseOmegaApi } from '@shared/api';
import { UploadDocumentRequest } from './types';

export const documentsApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getDocumentTypes: build.query<any, void>({
      query: () => ({ url: '/ips/getDocumentTypes' }),
      extraOptions: { auth: false, showLoader: true },
    }),
    getUserDocuments: build.query<any, void>({
      query: () => ({ url: '/ips/getUserDocuments' }),
      extraOptions: { auth: true, showLoader: true },
    }),
    uploadUserDocument: build.query<any, UploadDocumentRequest>({
      query: (payload: UploadDocumentRequest) => {
        const formData = new FormData();
        formData.append('file', payload.file);
        formData.append('documentType', payload.documentType);
        return {
          url: '/ips/uploadUserDocument',
          body: formData,
          method: 'POST',
        };
      },
      extraOptions: { auth: true, showLoader: false },
    }),
  }),
});

export const {
  useGetDocumentTypesQuery,
  useGetUserDocumentsQuery,
  useLazyUploadUserDocumentQuery,
} = documentsApiSlice;
