import { FC } from 'react'
import { Skeleton, Stack, Typography } from '@mui/joy';
import { featureToggles } from '@shared/config';
import { WithPullingChallenges } from '@entities/challenges';
import { LoyaltyProgressBar } from '@features/loyalty-progress-bar';
import { useSendDataToGtm } from '@features/collect-analytics';
import { MetaTagsBlock } from '@widgets/meta-tags-block';
import { LevelsSlider } from '@widgets/levels-slider';
import { TournamentsList } from '@widgets/tournaments-list';
import { ChallengeList } from '@widgets/challenge-list';
import {
  selectLuxeClubPageHeading,
  selectLuxeClubPageMetaCanonical,
  selectLuxeClubPageMetaDescription,
  selectLuxeClubPageMetaKeywords,
  selectLuxeClubPageMetaTitle,
  useLuxeClubPageSelector,
} from '../model';
import { useGetLuxeClubPageQuery } from '../api';

export const LuxeClubPage: FC = () => {
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'luxe-club'
    }
  });

  const title = useLuxeClubPageSelector(selectLuxeClubPageMetaTitle);
  const description = useLuxeClubPageSelector(selectLuxeClubPageMetaDescription);
  const keywords = useLuxeClubPageSelector(selectLuxeClubPageMetaKeywords);
  const canonical = useLuxeClubPageSelector(selectLuxeClubPageMetaCanonical);
  const heading = useLuxeClubPageSelector(selectLuxeClubPageHeading);

  const { isFetching } = useGetLuxeClubPageQuery();

  return (
    <>
      {isFetching && (
        <MetaTagsBlock
          title={title}
          description={description}
          keywords={keywords}
          canonical={canonical}
        />
      )}
      <Stack
        sx={({ breakpoints }) => ({
          width: '100%',
          height: '100%',
          gap: 8,
          maxWidth: 'calc(80rem + 10rem)',
          paddingInline: '5rem',
          paddingBlock: '3rem 4rem',
          [breakpoints.down(768)]: {
            gap: 6,
            paddingInline: '1rem',
            paddingBlock: '1.5rem',
          }
        })}>
        <Stack>
          <Typography sx={({ palette, breakpoints }) => ({
            fontSize: '1.75rem',
            fontWeight: 600,
            lineHeight: '2.25rem',
            color: palette.common.white,
            mb: '2rem',
            [breakpoints.down(768)]: {
              fontSize: '1.25rem',
              lineHeight: '1.875rem',
              mb: '1rem',
            }
          })}>
            {isFetching
              ? <Skeleton variant='text' width='5rem' />
              : heading}
          </Typography>
          <LoyaltyProgressBar />
        </Stack>
        <LevelsSlider />
        {featureToggles.challenges && (
          <WithPullingChallenges>
            <ChallengeList />
          </WithPullingChallenges>
        )}
        <TournamentsList
          size={3}
          smallHeader
        />
      </Stack>
    </>
  );
};