import { useRef } from 'react';

export const useScroll = (options?: { externalRef?: React.RefObject<HTMLDivElement> }) => {
  const internalRef = useRef(null);
  const ref = options?.externalRef || internalRef;

  const scrollTo = (position: 'top' | 'bottom') => {
    const targetPosition = position === 'top' ? 0 : ref.current?.scrollHeight;
    if (ref.current) {
      ref.current.scrollTo({ top: targetPosition, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: document.body.scrollHeight * (position === 'top' ? 0 : 1), behavior: 'smooth' });
    }
  };

  return { ref, scrollTo };
};
