import { baseOmegaApi } from '@shared/api';

export const coinPackagesApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getPackagesFromOmega: build.query<any, void>({
      query: () => ({ url: 'ips/getPackage', params: { currency: 'USD' } }),
      extraOptions: { auth: true, showLoader: true },
    }),
  }),
});

export const {
  useLazyGetPackagesFromOmegaQuery,
  useGetPackagesFromOmegaQuery
} = coinPackagesApiSlice;
