import {
  ComponentType,
  useEffect,
  useMemo
} from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/joy';
import { useToaster } from '@shared/ui';
import {
  useModalQueryParam,
  useQueryParamModalVisibility
} from '@shared/lib';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  UserKycStatusEnum,
  selectIsAuthenticated,
  selectKycStatus,
  selectRegistrationStatus,
  selectUserBalance,
  useSessionSelector
} from '@entities/session';
import {
  CoinRedemptionPopup,
  ConfirmCoinRedemptionPopup,
  PleaseConfirmYourIdentityModal,
  openConfirmCoinRedemptionPopup,
  selectIsOpenConfirmCoinRedemptionPopup,
  useRedeemCoinsSelector,
} from '@features/redeem-coins';
import {
  useSendDataToGtm
} from '@features/collect-analytics';

export const withCoinRedemptionPopup = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    const dispatch = useDispatch();
    const { error } = useToaster();
    const { closeModal } = useModalQueryParam();

    const isAuthenticated = useSessionSelector(selectIsAuthenticated);
    const kycStatus = useSessionSelector(selectKycStatus);
    const registrationStatus = useSessionSelector(selectRegistrationStatus);
    const userBalance = useSessionSelector(selectUserBalance);
    const openCheckoutCashier = useRedeemCoinsSelector(selectIsOpenConfirmCoinRedemptionPopup);
    const { sendDataToGtm } = useSendDataToGtm();

    const redeemCoinsPopup = useQueryParamModalVisibility('redeem');

    const allowRedeemCoins = useMemo(() =>
      registrationStatus === UserRegistrationStatusEnum.Player &&
      kycStatus === UserKycStatusEnum.Pass,
      [registrationStatus, kycStatus]
    );

    const openCoinRedemptionPopup = useMemo(
      () => redeemCoinsPopup && isAuthenticated && allowRedeemCoins,
      [redeemCoinsPopup, isAuthenticated, allowRedeemCoins]
    );

    const openPleaseConfirmYourIdentityModal = useMemo(
      () => redeemCoinsPopup && isAuthenticated && !allowRedeemCoins,
      [redeemCoinsPopup, isAuthenticated, allowRedeemCoins]
    );

    useEffect(() => {
      if (!isAuthenticated && redeemCoinsPopup) {
        error({
          message: 'You are not authorized! Please log in to your account to gain access',
          autoHideDuration: 5000
        });
      }
      // eslint-disable-next-line
    }, []);

    const handleClose = (): void => {
      closeModal('redeem');
    };

    const handleCoinRedeem = (amount: number): void => {
      closeModal('redeem');
      dispatch(openConfirmCoinRedemptionPopup(amount));
    };

    const handleRedeemStart = (amount: string): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_start',
          withdrawalAmount: amount,
          withdrawalCurrency: 'USD',
          withdrawalCount: '1',
          paymentMethod: 'Trustly'
        }
      })
    }

    const handleRedeemSuccess = (): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_success',
          paymentMethod: 'Trustly'
        }
      })
    }

    const handleRedeemFail = (): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_fail',
          paymentMethod: 'Trustly'
        }
      })
    }

    return (
      <>
        <WrappedComponent {...props} />
        <CoinRedemptionPopup
          open={openCoinRedemptionPopup}
          withdrawableBalance={userBalance?.withdrawableBalanceNumber ?? 0}
          bonusBalance={userBalance?.bonusBalanceNumber ?? 0}
          onClose={handleClose}
          onCoinRedeem={handleCoinRedeem}
        />
        <PleaseConfirmYourIdentityModal
          open={openPleaseConfirmYourIdentityModal}
          titleSlot={(
            <Typography
              level='h2'
              fontSize='1.25rem'>
              Redeem a prize
            </Typography>
          )}
          onClose={handleClose}
        />
        {openCheckoutCashier ? (
          <ConfirmCoinRedemptionPopup
            onRedeemFail={handleRedeemFail}
            onRedeemStart={handleRedeemStart}
            onRedeemSuccess={handleRedeemSuccess}
          />
        ) : null}
      </>
    );
  };
