import { mapper } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import {
  type Tournament,
  type TournamentAward,
  type BonusPlan,
  type AdditionalAward,
} from '../model';

type MapAdditionalAward = (data: any) => AdditionalAward;
const mapAdditionalAward: MapAdditionalAward = mapper([
  { name: 'id', from: 'id' },
  { name: 'awardId', from: 'awardId' },
  { name: 'awardSubType', from: 'awardSubType' },
  { name: 'awardType', from: 'awardType' },
  { name: 'bonusPlanId', from: 'bonusPlanId' },
  {
    name: 'amountWithCurrency',
    from: 'data',
    transform: (data: any) => {
      let deserialized: Maybe<{ currency: CurrencyCodesEnum; amount: number; }>;
      try {
        deserialized = JSON.parse(data);
      } catch { }
      const currencyMatrix: Record<CurrencyCodesEnum, string> = {
        [CurrencyCodesEnum.GCO]: 'GC',
        [CurrencyCodesEnum.SCO]: 'SC'
      };
      return deserialized
        && `${deserialized.amount} ${currencyMatrix[deserialized.currency]}`
    }
  },
]);

type MapBonusPlan = (data: any) => BonusPlan;
const mapBonusPlan: MapBonusPlan = mapper([
  { name: 'planId', from: 'planId' },
  { name: 'planName', from: 'planName' },
  { name: 'playable', from: 'playable' },
  { name: 'bonusAmount', from: 'bonusAmount' },
  { name: 'bonusAmounts', from: 'bonusAmounts' },
  { name: 'currency', from: 'currency' },
  { name: 'imageUrl', from: 'imageUrl' },
  { name: 'startDate', from: 'startDate' },
  { name: 'endDate', from: 'endDate' },
  { name: 'paymentSubmethod', from: 'paymentSubmethod' },
  {
    name: 'additionalAward',
    from: 'additionalAward',
    transform: (data: any) => {
      return data ? mapAdditionalAward(data) : null;
    }
  },
]);

type MapTournamentAward = (data: any) => TournamentAward;
const mapTournamentAward: MapTournamentAward = mapper([
  { name: 'id', from: 'id' },
  { name: 'type', from: 'type' },
  { name: 'noOfFreespin', from: 'noOfFreespin' },
  { name: 'loyaltyPoints', from: 'loyaltyPoints' },
  { name: 'description', from: 'description' },
  { name: 'createdDate', from: 'createdDate' },
  { name: 'lastModifiedDate', from: 'lastModifiedDate' },
  { name: 'tournamentId', from: 'tournamentId' },
  { name: 'bonusPlanId', from: 'bonusPlanId' },
  {
    name: 'bonusPlan',
    from: 'bonusPlan',
    transform: (data: any) => {
      return data ? mapBonusPlan(data) : null;
    }
  },
  { name: 'gameInfoId', from: 'gameInfoId' },
  { name: 'awardRuleId', from: 'awardRuleId' },
  { name: 'rankStart', from: 'rankStart' },
  { name: 'rankEnd', from: 'rankEnd' },
  { name: 'rankType', from: 'rankType' },
]);

type MapTournament = (data: any) => Tournament;
export const mapTournament: MapTournament = mapper([
  { name: 'name', from: 'name' },
  { name: 'endDate', from: 'endDate' },
  { name: 'startDate', from: 'startDate' },
  { name: 'status', from: 'status' },
  {
    name: 'awards',
    from: 'tournamentAwards',
    transform: (data: Maybe<Array<any>>) => {
      return data?.map(mapTournamentAward) ?? [];
    }
  },
  {
    name: 'description',
    from: 'description',
    transform: (data: any) => {
      let deserialized: { description?: Maybe<string> } = {};
      try {
        deserialized = JSON.parse(data);
      } catch { }
      return deserialized?.description
        ?? 'Join the thrilling SweepLuxe race.';
    }
  },
  {
    name: 'imageSrc',
    from: 'description',
    transform: (data: any) => {
      let deserialized: { imageSrc?: Maybe<string> } = {};
      try {
        deserialized = JSON.parse(data);
      } catch { }
      return deserialized?.imageSrc;
    }
  },
  {
    name: 'imageUrl',
    from: 'description',
    transform: (data: any) => {
      let deserialized: { imageUrl?: Maybe<string>; imageURL?: Maybe<string> } = {};
      try {
        deserialized = JSON.parse(data);
      } catch { }
      return deserialized?.imageUrl ?? deserialized?.imageURL;
    }
  },
  {
    name: 'prizeGC',
    from: 'description',
    transform: (data: any) => {
      let deserialized: { prizeGC?: Maybe<string> } = {};
      try {
        deserialized = JSON.parse(data);
      } catch { }
      return deserialized?.prizeGC ?? '';
    }
  },
  {
    name: 'prizeSC',
    from: 'description',
    transform: (data: any) => {
      let deserialized: { prizeSC?: Maybe<string> } = {};
      try {
        deserialized = JSON.parse(data);
      } catch { }
      return deserialized?.prizeSC ?? '';
    }
  },
  {
    name: 'buttonText',
    from: 'description',
    transform: (data: any) => {
      let deserialized: { buttonText?: Maybe<string> } = {};
      try {
        deserialized = JSON.parse(data);
      } catch { }
      return deserialized?.buttonText ?? 'Play now';
    }
  },
  {
    name: 'buttonUrl',
    from: 'description',
    transform: (data: any) => {
      let deserialized: { buttonUrl?: Maybe<string> } = {};
      try {
        deserialized = JSON.parse(data);
      } catch { }
      return deserialized?.buttonUrl ?? '/games/';
    }
  },
]);

type MapTournaments = (data: { tournamentList: Array<any> }) => Array<Tournament>;
export const mapTournaments: MapTournaments = ({
  tournamentList
}: {
  tournamentList: Array<any>
}) => {
  return tournamentList?.map(mapTournament) ?? [];
};
