import {
  FC
} from 'react';
import {
  Link
} from 'react-router-dom';
import {
  v4 as uuidv4
} from 'uuid';
import {
  Box,
  Stack,
  Typography
} from '@mui/joy';
import {
  selectPageFooterFollowUs,
  useLayoutSelector
} from '@entities/page-layout';

export const FollowUsBlock: FC = () => {
  const followUs = useLayoutSelector(selectPageFooterFollowUs);
  return (
    <Stack gap={1}>
      <Typography
        sx={({ colorSchemes, breakpoints }) => ({
          color: colorSchemes.dark.palette.common.white,
          alignSelf: 'center',
          [breakpoints.down(912)]: {
            alignSelf: 'start',
          },
        })}>
        {followUs?.title}
      </Typography>
      <Stack
        flexDirection='row'
        gap={2}
        sx={({ breakpoints }) => ({
          justifyContent: 'center',
          [breakpoints.down(912)]: {
            justifyContent: 'start'
          }
        })}>
        {followUs?.socialMedia?.map((socialMedia) => (
          <Link
            key={uuidv4()}
            to={socialMedia.href}>
            <Box
              component='img'
              src={socialMedia.icon}
              sx={{
                width: '31px',
                height: '31px',
              }}
            />
          </Link>
        ))}
      </Stack>
    </Stack>
  );
};
