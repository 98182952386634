import {
  FC,
  PropsWithChildren,
  useEffect
} from 'react';
import {
  useGetLoyaltyPointsQuery,
  useGetPlayerInfoQuery
} from '../api';

export const WithPullingPlayerInfo: FC<PropsWithChildren> = ({ children }) => {
  const { refetch: getPlayerInfo } = useGetPlayerInfoQuery();
  const { refetch: getLoyaltyPoints } = useGetLoyaltyPointsQuery();
  useEffect(() => {
    getPlayerInfo();
    getLoyaltyPoints();
  }, [getPlayerInfo, getLoyaltyPoints]);
  return <>{children}</>
};