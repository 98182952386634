import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchMessagesResponse, fetchMessages } from '../api';
import {
  type MessagesState,
  type SetMessagePayload,
  type ChannelMessage,
  type ClearMessagePayload,
  type ReadMessagesPayload,
} from './types';

const initialState: MessagesState = {
  channels: {},
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessage: (state: MessagesState, action: PayloadAction<SetMessagePayload>) => {
      const { channelId, message } = action.payload;

      const newMessages: Array<ChannelMessage> = [
        ...(state.channels[channelId] ?? []).filter(
          (messageInStore: ChannelMessage) => messageInStore.id !== message.id
        ),
        message,
      ];
      state.channels[channelId] = newMessages;
    },
    clearMessage: (state: MessagesState, action: PayloadAction<ClearMessagePayload>) => {
      const { channelId } = action.payload;

      const newMessages: Array<ChannelMessage> = [...(state.channels[channelId] ?? []).slice(1)];
      state.channels[channelId] = newMessages;
    },
    clearAllMessages: (state: MessagesState, action: PayloadAction<ClearMessagePayload>) => {
      const { channelId } = action.payload;
      state.channels[channelId] = [];
    },
    readAllMessages: (state: MessagesState, action: PayloadAction<ReadMessagesPayload>) => {
      const { channelId } = action.payload;

      const newMessages: Array<ChannelMessage> = (state.channels[channelId] ?? []).map((message: ChannelMessage) => ({
        ...message,
        isRead: true,
      }));
      state.channels[channelId] = newMessages;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.fulfilled, (state: MessagesState, action: PayloadAction<FetchMessagesResponse>) => {
      const oldMessages = state.channels[action.payload.channelId] ?? [];

      state.channels[action.payload.channelId] = [
        ...oldMessages,
        ...action.payload.messages.filter((message: ChannelMessage) =>
          !oldMessages.find((oldMessage: ChannelMessage) => oldMessage.id === message.id)
        ),
      ];
    });
  },
});

export const messagesReducer = messagesSlice.reducer;
export const { setMessage, clearMessage, readAllMessages, clearAllMessages } = messagesSlice.actions;
