import { FC } from 'react';
import { Link as JoyLink, LinkProps as JoyLinkProps } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

export interface LinkProps extends Pick<
  JoyLinkProps,
  'color'
  | 'children'
  | 'underline'
  | 'href'
  | 'target'
  | 'onClick'
  | 'sx'
> {
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'neutral' | 'success';
  disabled?: boolean;
};

export const Link: FC<LinkProps> = ({ children, href, ...props }: LinkProps) => {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (href && href.startsWith('/')) {
      event.preventDefault();
      navigate(href);
    }
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <JoyLink {...props} href={href} onClick={handleClick}>
      {children}
    </JoyLink>
  );
};
