import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  type SignUpState,
  type SignUp
} from './types';

const initialState: SignUpState = {
  openConfirmationSignUpModal: false,
  openSuccessConfirmationSignUpModal: false,
  propsToSignUp: null
};

export const signUpSlice = createSlice({
  name: 'sign-up',
  initialState,
  reducers: {
    openConfirmationSignUpModal: (state: SignUpState) => {
      state.openConfirmationSignUpModal = true;
    },
    closeConfirmationSignUpModal: (state: SignUpState) => {
      state.openConfirmationSignUpModal = false;
    },
    openSuccessConfirmationSignUpModal: (state: SignUpState) => {
      state.openSuccessConfirmationSignUpModal = true;
    },
    closeSuccessConfirmationSignUpModal: (state: SignUpState) => {
      state.openSuccessConfirmationSignUpModal = false;
    },
    setPropsToSignUp: (state: SignUpState, action: PayloadAction<SignUp>) => {
      state.propsToSignUp = action.payload;
    },
    removePropsToSignUp: (state: SignUpState) => {
      state.propsToSignUp = null;
    }
  }
});

export const {
  openConfirmationSignUpModal,
  closeConfirmationSignUpModal,
  openSuccessConfirmationSignUpModal,
  closeSuccessConfirmationSignUpModal,
  setPropsToSignUp,
  removePropsToSignUp,
} = signUpSlice.actions;

export const signUpSliceReducer = signUpSlice.reducer;
