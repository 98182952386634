import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CurrencyCodesEnum, UserStateEnum } from '@shared/types';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { playerApiSlicer, authApiSlicer } from '../api';
import {
  mapFullProfile,
  mapLoyaltyStatus,
  mapUserBalance,
} from '../lib';
import {
  type SessionState,
  type FullProfile
} from './types';

const initialState: SessionState = {
  shortProfile: null,
  fullProfile: null,
  currencySessionKeys: null,
  sessionKey: '',
  toggledCurrencyCode: CurrencyCodesEnum.SCO,
  userState: UserStateEnum.guestUser,
  emailPromotionSubscription: false,
  loyaltyStatus: null,
  userBalance: null,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    initializeState: (state: SessionState, payload: PayloadAction<SessionState>) => {
      state = { ...payload.payload };
    },
    toggleCurrencyCode: (state: SessionState, action: PayloadAction<CurrencyCodesEnum>) => {
      state.toggledCurrencyCode = action.payload;
    },
    signOut: (state: SessionState) => {
      Object.assign(state, initialState, {
        userState: UserStateEnum.userIsLoggedOut,
      });
    },
    updateFullProfile: (state: SessionState, action: PayloadAction<FullProfile>) => {
      state.fullProfile = { ...state.fullProfile, ...action.payload };
    },
    setSignedInData: (
      state: SessionState,
      action: PayloadAction<Pick<SessionState, 'currencySessionKeys' | 'sessionKey' | 'shortProfile'>>
    ) => {
      state.shortProfile = action.payload.shortProfile;
      state.sessionKey = action.payload.sessionKey;
      state.currencySessionKeys = action.payload.currencySessionKeys;
      state.userState = UserStateEnum.userIsLogged;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<SessionState>) => {
    builder.addMatcher(
      playerApiSlicer.endpoints.balance.matchFulfilled,
      (state: SessionState, action: PayloadAction<any>) => {
        if (action.payload.status === OmegaApiResponseStatusEnum.Success) {
          state.userBalance = mapUserBalance(action.payload);
        }
      }
    );
    builder.addMatcher(
      playerApiSlicer.endpoints.getPlayerInfo.matchFulfilled,
      (state: SessionState, action: PayloadAction<any>) => {
        state.fullProfile = mapFullProfile(action.payload);
      }
    );
    builder.addMatcher(
      playerApiSlicer.endpoints.getLoyaltyPoints.matchFulfilled,
      (state, action) => {
        if (action.payload.status === OmegaApiResponseStatusEnum.Success) {
          state.loyaltyStatus = mapLoyaltyStatus(action.payload)
        }
      });
    builder.addMatcher(
      authApiSlicer.endpoints.signOut.matchFulfilled,
      (state: SessionState) => {
        Object.assign(state, initialState, {
          userState: UserStateEnum.userIsLoggedOut,
        });
      }
    );
  },
});

export const {
  signOut,
  toggleCurrencyCode,
  updateFullProfile,
  setSignedInData,
} = sessionSlice.actions;

export const sessionSliceReducer = sessionSlice.reducer;
