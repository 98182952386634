import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const RocketIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clip-path='url(#clip0_7288_229172)'>
      <path
        d='M21.3509 23.2458L20.1741 17.0938L15.8172 20.5291L17.5622 22.7771L13.3247 26.6814C13.2404 26.759 13.1854 26.8633 13.1691 26.9767C13.1528 27.0902 13.1762 27.2058 13.2353 27.304C13.2944 27.4021 13.3855 27.4769 13.4934 27.5157C13.6013 27.5544 13.7192 27.5547 13.8272 27.5165L20.1865 25.2736C20.5911 25.1308 20.931 24.8477 21.1447 24.4756C21.3583 24.1035 21.4315 23.6672 21.3509 23.2458Z'
        fill='#EF5361'
      />
      <path
        d='M9.18943 11.0905L15.3415 12.2671L11.9061 16.6242L9.65818 14.8791L5.75387 19.117C5.67625 19.2012 5.57195 19.2561 5.45857 19.2723C5.3452 19.2886 5.22969 19.2652 5.13156 19.2061C5.03342 19.147 4.95868 19.0559 4.91994 18.9481C4.8812 18.8404 4.88085 18.7225 4.91893 18.6145L7.16162 12.2552C7.30437 11.8506 7.58748 11.5106 7.95955 11.2969C8.33162 11.0831 8.76798 11.0099 9.18943 11.0905Z'
        fill='#EF5361'
      />
      <path
        d='M22.8749 5C20.5699 5.71062 17.7681 7.28225 15.1347 10.6434C12.0989 14.5181 10.4577 16.7405 9.57812 17.9885L14.4642 22.8746C15.7122 21.995 17.9346 20.3538 21.8093 17.318C25.1702 14.6846 26.7421 11.8828 27.4531 9.578L22.8749 5Z'
        fill='#DBE2EB'
      />
      <path
        d='M21.8104 17.3172C17.9353 20.3523 15.7136 21.9933 14.4649 22.874L13.5703 21.9794C14.8194 21.0997 17.0422 19.4592 20.9158 16.4228C24.2775 13.7896 25.8488 10.9876 26.5596 8.68359L27.4526 9.57684C26.7433 11.882 25.1706 14.6827 21.8104 17.3172Z'
        fill='#C8CBD2'
      />
      <path
        d='M27.9714 5.2781C27.9553 5.07247 27.8664 4.87933 27.7206 4.73342C27.5749 4.58751 27.3818 4.49841 27.1762 4.48216C25.4674 4.37066 23.7547 4.63114 22.1562 5.24566L27.2079 10.2973C27.8223 8.6991 28.0828 6.98668 27.9714 5.2781Z'
        fill='#EF5361'
      />
      <path
        d='M27.2056 10.2983L26.3125 9.40519C26.9139 7.835 27.1764 6.15537 27.0827 4.47656C27.1135 4.47938 27.1441 4.48219 27.1735 4.4835C27.379 4.49984 27.5719 4.58889 27.7177 4.73466C27.8635 4.88043 27.9525 5.07337 27.9689 5.27888C28.0795 6.98747 27.8191 8.69977 27.2056 10.2983Z'
        fill='#DA2A47'
      />
      <path
        d='M10.116 17.2305C9.53174 18.0367 9.16574 18.5703 8.93905 18.9146C8.83574 19.0714 8.78978 19.2591 8.80894 19.4459C8.82811 19.6327 8.91123 19.8071 9.04424 19.9397L12.5089 23.4043C12.6415 23.5372 12.8159 23.6203 13.0027 23.6394C13.1895 23.6586 13.3771 23.6126 13.5339 23.5093C13.8782 23.2826 14.4114 22.9166 15.2181 22.3323L10.116 17.2305Z'
        fill='#EF5361'
      />
      <path
        d='M15.2202 22.3306C14.4123 22.9154 13.8792 23.281 13.5359 23.5069C13.3792 23.611 13.1914 23.6574 13.0043 23.6383C12.8173 23.6192 12.6426 23.5358 12.5103 23.4023L11.6172 22.5093C11.7499 22.6422 11.9245 22.7253 12.1114 22.7443C12.2982 22.7634 12.486 22.7173 12.6428 22.6139C12.9861 22.3879 13.5192 22.0223 14.3271 21.4375L15.2202 22.3306Z'
        fill='#DA2A47'
      />
      <path
        d='M21.7408 14.1153C23.0709 13.564 23.7022 12.0388 23.1509 10.7087C22.5996 9.3786 21.0744 8.74728 19.7443 9.29861C18.4142 9.84994 17.7829 11.3751 18.3342 12.7052C18.8855 14.0353 20.4107 14.6666 21.7408 14.1153Z'
        fill='#43567C'
      />
      <path
        d='M22.5811 13.5522C22.339 13.7944 22.0517 13.9865 21.7354 14.1176C21.4191 14.2486 21.08 14.3161 20.7377 14.3161C20.3953 14.3161 20.0563 14.2486 19.74 14.1176C19.4237 13.9865 19.1363 13.7944 18.8943 13.5522C18.7969 13.4543 18.7069 13.3494 18.625 13.2383C19.1272 13.6019 19.7426 13.7745 20.3606 13.7253C20.9786 13.6761 21.5589 13.4083 21.9973 12.9699C22.4356 12.5315 22.7035 11.9513 22.7527 11.3332C22.8019 10.7152 22.6292 10.0999 22.2657 9.59766C22.3767 9.67964 22.4816 9.76963 22.5796 9.86691C23.0684 10.3555 23.3431 11.0181 23.3434 11.7093C23.3437 12.4004 23.0695 13.0633 22.5811 13.5522Z'
        fill='#384868'
      />
      <path
        d='M9.81737 22.3505C9.81737 22.3505 11.671 23.8334 10.5323 25.7667C9.39363 27.7 7.80475 27.2761 7.80475 27.2761C7.80475 27.2761 8.33444 26.5169 8.25475 26.2786C8.25475 26.2786 6.31281 28.609 4 26.8435C4 26.8435 4.97106 26.4728 5.17394 25.6696C5.37681 24.8663 5.7655 24.1072 6.25094 23.7453C6.11116 23.8785 5.93497 23.9673 5.7447 24.0002C5.55443 24.0332 5.35865 24.009 5.18219 23.9305C5.34853 23.8399 5.48384 23.7015 5.57062 23.5331C5.6574 23.3647 5.69165 23.1742 5.66894 22.9861C5.58906 22.324 8.06894 20.5673 9.81737 22.3505Z'
        fill='#EF5361'
      />
      <path
        d='M10.0344 22.5597C10.0344 22.5597 9.1678 21.4765 8.03811 22.142C6.90843 22.8074 7.15686 23.7357 7.15686 23.7357C7.15686 23.7357 7.60049 23.4263 7.7398 23.4732C7.7398 23.4732 6.37799 24.608 7.40961 25.9595C7.40961 25.9595 7.62636 25.3921 8.09568 25.2734C8.56499 25.1547 9.00861 24.9278 9.22068 24.6441C9.14296 24.7258 9.09119 24.8287 9.0719 24.9398C9.05261 25.0509 9.06667 25.1652 9.1123 25.2683C9.16541 25.1713 9.24639 25.0924 9.34478 25.0418C9.44318 24.9912 9.55448 24.9713 9.6643 24.9846C10.0498 25.0304 11.0764 23.581 10.0344 22.5597Z'
        fill='#FFC943'
      />
    </g>
    <defs>
      <clipPath id='clip0_7288_229172'>
        <rect width='24' height='24' fill='white' transform='translate(4 4)' />
      </clipPath>
    </defs>
  </SvgIcon>
);
