import {
  FC,
  RefObject,
  useEffect,
  useRef,
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import {
  SwiperSlide
} from 'swiper/react';
import {
  Navigation
} from 'swiper/modules';
import {
  Stack,
  // Skeleton,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  selectIsAuthenticated,
  useSessionSelector
} from '@entities/session';
import {
  TournamentsListItem,
  fetchTournaments,
  selectIsLoading,
  selectTournaments,
  useTournamentsSelector,
} from '@entities/tournaments';
import {
  TournamentsListHeader
} from './tournaments-list-header.component';
import {
  TournamentsListHeaderSmall
} from './tournaments-list-header-small.component';
import { Swiper } from './swiper.component';

type TournamentsListProps = {
  title?: Maybe<string>;
  smallHeader?: Maybe<boolean>;
  size?: Maybe<number>;
};

export const TournamentsList: FC<TournamentsListProps> = ({ title, smallHeader, size }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(912));

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const isLoading = useTournamentsSelector(selectIsLoading);
  const tournaments = useTournamentsSelector(selectTournaments);

  const navigationNextRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const navigationPrevRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    dispatch(fetchTournaments(isAuthenticated))
  }, [dispatch, isAuthenticated]);

  return tournaments.length ? (
    <Stack
      gap={2}
      sx={({ breakpoints }) => ({
        [breakpoints.up(1024)]: {
          minHeight: 612
        }
      })}>
      {smallHeader ? (
        <TournamentsListHeaderSmall title={title} />
      ) : (
        <TournamentsListHeader
          title={title}
          navigationNextRef={navigationNextRef}
          navigationPrevRef={navigationPrevRef}
        />
      )}
      <Stack
        direction='row'
        gap={2.5}
        sx={({ breakpoints }) => ({
          [breakpoints.down(912)]: {
            width: '2000px',
            marginLeft: '-16px',
            paddingInline: '16px',
            gap: 1,
            'div': {
              width: 410,
            },
          },
          [breakpoints.down(442)]: {
            width: 'calc(100% * 3)',
            'div': {
              width: '100%',
            },
          },
        })}>
        {/* {Array(3).fill(null).map((_, index) => (
          <Skeleton
            key={index}
            variant='rectangular'
            sx={{
              width: '100%',
              maxWidth: 410,
              height: 510,
              borderRadius: 8,
            }}
          />
        ))} */}
      </Stack>
      <Swiper
        sx={{
          display: 'block',
          ...(isLoading && ({
            display: 'hidden'
          })),
        }}
        slidesPerView={isMobile ? 'auto' : 3}
        spaceBetween={isMobile ? 8 : 24}
        modules={[Navigation]}
        navigation={{
          enabled: true,
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}>
        {tournaments
          .slice(0, size ?? 4)
          .map(tournament => (
            <SwiperSlide key={tournament.name}>
              <TournamentsListItem
                {...tournament}
                sx={{
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </Stack>
  ) : null;
};
