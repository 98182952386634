import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { coinPackagesSlice } from './slice';
import {
  type CoinPackagesState,
  type Package
} from './types';

type RootState = {
  [coinPackagesSlice.name]: CoinPackagesState;
};

export const selectCoinPackages = (state: RootState): Array<Package> => (
  state[coinPackagesSlice.name].packages ?? []
);

export const selectOpenCheckoutModal = (state: RootState): boolean => (
  state[coinPackagesSlice.name].openCheckoutModal
);

export const selectCheckoutModalProps = (state: RootState): Maybe<Partial<Package>> => (
  state[coinPackagesSlice.name]?.checkoutModalProps
);

export const useCoinPackagesSelector: TypedUseSelectorHook<{
  [coinPackagesSlice.name]: CoinPackagesState;
}> = useSelector;