import {
  FC,
  ReactNode,
} from 'react';
import {
  Divider,
  Stack
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  AppLogo
} from '@shared/ui';
import {
  UserAvatarWithNickname,
  selectIsAuthenticated,
  useSessionSelector
} from '@entities/session';
import {
  useLayoutSelector,
  selectPageHeaderVariant1LogoImgSrc,
  selectPageHeaderVariant2LogoImgSrc,
} from '@entities/page-layout';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  BuyCoinsButton
} from '@features/buy-coins';
import {
  SignInButton
} from '@features/sign-in';
import {
  SignUpButton
} from '@features/sign-up';

export type LayoutHeaderProps = {
  sidebarMenuSlot?: ReactNode;
  sidebarMenuButtonSlot?: ReactNode;
  gameToggleCoinsSlot?: ReactNode;
  toggleCoinsSlot?: ReactNode;
  notificationsSidebarSlot?: ReactNode;
  notificationsSidebarButtonSlot?: ReactNode;
};

export const LayoutHeader: FC<LayoutHeaderProps> = ({
  sidebarMenuSlot,
  sidebarMenuButtonSlot,
  gameToggleCoinsSlot,
  toggleCoinsSlot,
  notificationsSidebarSlot,
  notificationsSidebarButtonSlot,
}) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down(912));
  const isTablet = useMediaQuery(breakpoints.down(1024));

  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const variant1LogoImgSrc = useLayoutSelector(selectPageHeaderVariant1LogoImgSrc);
  const variant2LogoImgSrc = useLayoutSelector(selectPageHeaderVariant2LogoImgSrc);
  const { sendDataToGtm } = useSendDataToGtm();

  const handleOpenSignUpModal = (): void => {
    sendDataToGtm({
      dataLayer: {
        event: 'registration_click',
      }
    });
  };

  const handleOpenBuyCoinsModal = (): void => {
    sendDataToGtm({
      dataLayer: {
        event: 'coin_store_click',
      }
    });
  };

  return (
    <Stack
      component='header'
      justifyContent='center'
      alignItems='center'
      sx={({ palette }) => ({
        zIndex: 5,
        width: '100%',
        paddingInline: isMobile ? 2 : 10,
        backgroundColor: palette.common[475],
      })}>
      {sidebarMenuSlot}
      {notificationsSidebarSlot}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          width: '100%',
          maxWidth: 1280,
          height: 68,
          gap: 0.5
        }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          gap={1.25}>
          {isMobile ? (
            variant1LogoImgSrc ? (
              <AppLogo
                src={variant1LogoImgSrc}
                sx={{
                  width: 60,
                  height: 30,
                }}
              />
            ) : null) : (
            <>
              {sidebarMenuButtonSlot}
              {variant2LogoImgSrc ? (
                <AppLogo
                  src={variant2LogoImgSrc}
                  sx={{
                    width: 210,
                    height: 35.4,
                  }}
                />
              ) : null}
            </>
          )}
        </Stack>
        {gameToggleCoinsSlot}
        {isAuthenticated ? (
          <Stack
            direction='row'
            alignItems='center'
            gap={2}>
            <Stack
              direction='row'
              alignItems='center'
              gap={0.5}>
              {toggleCoinsSlot}
              <BuyCoinsButton
                onOpenBuyCoinsModal={handleOpenBuyCoinsModal}>
                Buy
              </BuyCoinsButton>
            </Stack>
            {!isTablet && (
              <>
                <Divider
                  orientation='vertical'
                  sx={{ marginBlock: 1 }}
                />
                <UserAvatarWithNickname />
                <Divider
                  orientation='vertical'
                  sx={{ marginBlock: 1 }}
                />
                {notificationsSidebarButtonSlot}
              </>
            )}
          </Stack>
        ) : (
          <Stack
            direction='row'
            alignItems='center'
            gap={1.5}>
            <Stack
              direction='row'
              alignItems='center'
              gap={1}>
              <SignInButton>
                Log in
              </SignInButton>
              <SignUpButton
                onOpenSignUpModal={handleOpenSignUpModal}>
                Sign Up
              </SignUpButton>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
