import {
  ComponentType,
  useEffect,
  useMemo
} from 'react';
import { Typography } from '@mui/joy';
import { useToaster } from '@shared/ui';
import {
  useQueryParamModalVisibility,
  useModalQueryParam
} from '@shared/lib';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  useSessionSelector,
  selectIsAuthenticated,
  UserKycStatusEnum,
  selectRegistrationStatus,
  selectKycStatus,
} from '@entities/session';
import {
  selectOpenCheckoutModal,
  useCoinPackagesSelector
} from '@entities/coin-packages';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  CheckoutModal,
  CoinPackagesModal,
  PleaseConfirmYourIdentityModal
} from '@features/buy-coins';

export const withBuyModal = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    const { error } = useToaster();
    const { closeModal } = useModalQueryParam();

    const isAuthenticated = useSessionSelector(selectIsAuthenticated);
    const kycStatus = useSessionSelector(selectKycStatus);
    const registrationStatus = useSessionSelector(selectRegistrationStatus);
    const openCheckoutModal = useCoinPackagesSelector(selectOpenCheckoutModal);

    const { sendDataToGtm } = useSendDataToGtm();

    const openBuyModal = useQueryParamModalVisibility('buy');

    const allowToBuy = useMemo(() =>
      registrationStatus === UserRegistrationStatusEnum.Player &&
      kycStatus === UserKycStatusEnum.Pass,
      [registrationStatus, kycStatus]
    );

    const openCoinPackagesModal = useMemo(
      () => openBuyModal && isAuthenticated && allowToBuy,
      [openBuyModal, isAuthenticated, allowToBuy]
    );

    const openPleaseConfirmYourIdentityModal = useMemo(
      () => openBuyModal && isAuthenticated && !allowToBuy,
      [openBuyModal, isAuthenticated, allowToBuy]
    );

    useEffect(() => {
      if (!isAuthenticated && openBuyModal)
        error({
          message: 'You are not authorized! Please log in to your account to gain access',
          autoHideDuration: 1000
        });
      // eslint-disable-next-line
    }, []);

    const handleClose = (): void => {
      closeModal('buy');
    };

    const handleDepositStart = (): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_start',
        }
      })
    }

    const handleDepositSuccess = (amount: string): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_success',
          depositAmount: amount,
          depositCurrency: 'USD',
          depositcount: '1',
          paymentMethod: 'Trustly'
        }
      })
    }

    const handleDepositFail = (): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_fail',
          paymentMethod: 'Trustly'
        }
      })
    }

    return (
      <>
        <WrappedComponent {...props} />
        {openCoinPackagesModal ? (
          <CoinPackagesModal
            open={openCoinPackagesModal}
            titleSlot={(
              <Typography
                level='h2'
                fontSize='1.25rem'>
                Coin packages
              </Typography>
            )}
            onClose={handleClose}
          />
        ) : null}
        <PleaseConfirmYourIdentityModal
          open={openPleaseConfirmYourIdentityModal ?? false}
          titleSlot={(
            <Typography
              level='h2'
              fontSize='1.25rem'>
              Coin packages
            </Typography>
          )}
          onClose={handleClose}
        />
        {openCheckoutModal ? (
          <CheckoutModal
            onDepositFail={handleDepositFail}
            onDepositStart={handleDepositStart}
            onDepositSuccess={handleDepositSuccess}
          />
        ) : null}
      </>
    );
  };
