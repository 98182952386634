import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { coinPackagesApiSlice } from '../api';
import {
  type Package,
  type CoinPackagesState,
} from './types';

const initialState: CoinPackagesState = {
  packages: [],
  openCheckoutModal: false,
  checkoutModalProps: null,
};

export const coinPackagesSlice = createSlice({
  name: 'coin-package',
  initialState,
  reducers: {
    openCheckoutModal: (state: CoinPackagesState, action: PayloadAction<Partial<Package>>) => {
      state.openCheckoutModal = true;
      state.checkoutModalProps = action.payload;
    },
    closeCheckoutModal: (state: CoinPackagesState) => {
      state.openCheckoutModal = false;
      state.checkoutModalProps = null;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      coinPackagesApiSlice.endpoints.getPackagesFromOmega.matchFulfilled,
      (state: CoinPackagesState, action: PayloadAction<any>) => {
        state.packages = action.payload.data;
      }
    );
  },
});

export const { openCheckoutModal, closeCheckoutModal } = coinPackagesSlice.actions;
export const coinPackagesReducer = coinPackagesSlice.reducer;