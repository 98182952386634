import { combineReducers } from 'redux';
import { baseApi, baseOmegaApi } from '@shared/api';
import {
  circularLoaderSlice,
  circularLoaderReducer
} from '@shared/ui/circular-loader';
import { featureToggles } from '@shared/config';
import {
  sessionSlice,
  sessionSliceReducer,
} from '@entities/session';
import {
  gamesReducer,
  gamesSlice,
} from '@entities/games';
import {
  messagesSlice,
  messagesReducer
} from '@entities/messages';
import {
  pageLayoutSlice,
  pageLayoutReducer,
} from '@entities/page-layout';
import {
  coinPackagesSlice,
  coinPackagesReducer,
} from '@entities/coin-packages';
import {
  transactionsSlice,
  transactionsReducer,
} from '@entities/transactions';
import {
  gameWinnersSlice,
  gameWinnersReducer,
} from '@entities/game-winners';
import {
  tournamentsSlice,
  tournamentsReducer
} from '@entities/tournaments';
import {
  challengesSlice,
  challengesReducer
} from '@entities/challenges';
import {
  signUpSlice,
  signUpSliceReducer
} from '@features/sign-up';
import {
  managePromotionalSubscriptionsSlice,
  managePromotionalSubscriptionsReducer,
} from '@features/manage-promotional-subscriptions';
import {
  resetPasswordSlice,
  resetPasswordReducer
} from '@features/reset-password';
import {
  gamesFilteredByCategoriesSlice,
  gamesFilteredByCategoriesReducer
} from '@features/games-filter-by-category';
import {
  gamesFilteredBySearchSlice,
  gamesFilteredBySearchReducer
} from '@features/games-filter-by-search';
import {
  gamesFilteredByFavoriteSlice,
  gamesFilteredByFavoriteReducer
} from '@features/games-filter-by-favorite';
import {
  documentsSlice,
  documentsReducer
} from '@features/upload-documents';
import {
  redeemCoinsSlice,
  redeemCoinsReducer
} from '@features/redeem-coins';
import {
  transactionFilterByTypeSlice,
  transactionFilterByTypeReducer
} from '@features/transaction-filter-by-type';
import {
  checkTrackingCodesSlice,
  checkTrackingCodesReducer
} from '@features/check-tracking-codes';
import {
  authViaSocialsSlice,
  authViaSocialsReducer
} from '@features/authentication-via-socials';
import { 
  promotionsFilteredByCategoriesSlice,
  promotionsFilteredByCategoriesReducer 
} from '@features/promotions-filter-by-category';
import {
  sidebarMenuSlice,
  sidebarMenuReducer
} from '@widgets/sidebar-menu';
import {
  notificationsSidebarSlice,
  notificationsSidebarReducer,
} from '@widgets/notifications-sidebar';
import {
  transactionListReducer,
  transactionListSlice
} from '@widgets/transaction-list';
import {
  gamesListWidgetSlice,
  gamesListWidgetReducer
} from '@widgets/game-list';
import {
  homePageSlice,
  homePageReducer,
} from '@pages/home';
import {
  gamesPageReducer,
  gamesPageSlice
} from '@pages/games';
import {
  tournamentsPageReducer,
  tournamentsPageSlice
} from '@pages/tournaments';
import {
  dynamicPageReducer,
  dynamicPageSlice,
} from '@pages/dynamic';
import {
  promotionsPageReducer,
  promotionsPageSlice
} from '@pages/promotions';
import {
  accountPageSlice,
  accountPageReducer
} from '@pages/account';
import {
  luxeClubPageReducer,
  luxeClubPageSlice,
} from '@pages/luxe-club';
import {
  tournamentDetailsPageReducer,
  tournamentDetailsPageSlice,
} from '@pages/tournament-details';
import {
  promotionDetailsPageReducer,
  promotionDetailsPageSlice
} from '@pages/promotion-details';
import {
  challengesPageReducer,
  challengesPageSlice
} from '@pages/challenges';
import { 
  playGamePageSlice,
  playGamePageReducer
} from '@pages/play-game';

export const rootReducer = combineReducers({
  [circularLoaderSlice.name]: circularLoaderReducer,
  [pageLayoutSlice.name]: pageLayoutReducer,
  [coinPackagesSlice.name]: coinPackagesReducer,
  [tournamentsSlice.name]: tournamentsReducer,
  [sidebarMenuSlice.name]: sidebarMenuReducer,
  [notificationsSidebarSlice.name]: notificationsSidebarReducer,
  [homePageSlice.name]: homePageReducer,
  [sessionSlice.name]: sessionSliceReducer,
  [signUpSlice.name]: signUpSliceReducer,
  [gamesPageSlice.name]: gamesPageReducer,
  [gamesSlice.name]: gamesReducer,
  [tournamentsPageSlice.name]: tournamentsPageReducer,
  [tournamentDetailsPageSlice.name]: tournamentDetailsPageReducer,
  [messagesSlice.name]: messagesReducer,
  [gamesFilteredByCategoriesSlice.name]: gamesFilteredByCategoriesReducer,
  [gamesFilteredBySearchSlice.name]: gamesFilteredBySearchReducer,
  [gamesFilteredByFavoriteSlice.name]: gamesFilteredByFavoriteReducer,
  [documentsSlice.name]: documentsReducer,
  [gamesListWidgetSlice.name]: gamesListWidgetReducer,
  [dynamicPageSlice.name]: dynamicPageReducer,
  [promotionsPageSlice.name]: promotionsPageReducer,
  [accountPageSlice.name]: accountPageReducer,
  [managePromotionalSubscriptionsSlice.name]: managePromotionalSubscriptionsReducer,
  [resetPasswordSlice.name]: resetPasswordReducer,
  [transactionsSlice.name]: transactionsReducer,
  [transactionListSlice.name]: transactionListReducer,
  [redeemCoinsSlice.name]: redeemCoinsReducer,
  [gameWinnersSlice.name]: gameWinnersReducer,
  [luxeClubPageSlice.name]: luxeClubPageReducer,
  [transactionFilterByTypeSlice.name]: transactionFilterByTypeReducer,
  [checkTrackingCodesSlice.name]: checkTrackingCodesReducer,
  [authViaSocialsSlice.name]: authViaSocialsReducer,
  [promotionDetailsPageSlice.name]: promotionDetailsPageReducer,
  ...(featureToggles.challenges && ({
    [challengesSlice.name]: challengesReducer,
    [challengesPageSlice.name]: challengesPageReducer,
  })),
  [playGamePageSlice.name]: playGamePageReducer,
  [promotionsFilteredByCategoriesSlice.name]: promotionsFilteredByCategoriesReducer,

  // API
  // TODO: maybe remove all api slices except baseOmegaApi and baseApi
  [baseApi.reducerPath]: baseApi.reducer,
  [baseOmegaApi.reducerPath]: baseOmegaApi.reducer,
});
