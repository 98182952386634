import { createSelector } from '@reduxjs/toolkit';
import {
  type ShortProfile,
  selectShortProfile
} from '@entities/session';
import {
  type Tournament,
  type TournamentRanking,
  type TournamentAward,
  selectTournaments,
  selectTournamentRankings,
} from '@entities/tournaments';
import { TournamentPlaceEnum } from './types';
import { isDefined } from '@shared/lib';

const findTournamentAwardForRanking = (
  tournament: Tournament,
  ranking: number
): Maybe<TournamentAward> => {
  return tournament.awards?.find(({ rankStart, rankEnd }) =>
    ranking >= rankStart! && ranking <= rankEnd!
  );
};

export const selectPlaces = createSelector(
  selectShortProfile,
  selectTournaments,
  selectTournamentRankings,
  (
    shortProfile: Maybe<ShortProfile>,
    tournaments: Array<Tournament>,
    tournamentRankings: Array<TournamentRanking>
  ) => {
    if (!isDefined(tournaments) || !isDefined(tournamentRankings)) {
      return null;
    }

    const sortedRankings = [...tournamentRankings]
      .sort((a, b) => a.ranking - b.ranking);

    const result = [];
    const tournament = tournaments[0];

    const topRankings = sortedRankings.slice(0, 3);
    topRankings.forEach((ranking, index) => {
      const award = findTournamentAwardForRanking(tournament, ranking.ranking);

      const bonusPlanAmountWithCurrency = isDefined(award?.bonusPlan?.bonusAmount)
        ? `${award?.bonusPlan.bonusAmount} SC`
        : isDefined(award?.loyaltyPoints)
          ? `${award?.loyaltyPoints} LP`
          : '-';
      const toWin = award?.bonusPlan?.additionalAward?.amountWithCurrency
        ? `${award?.bonusPlan?.additionalAward?.amountWithCurrency} + ${bonusPlanAmountWithCurrency}`
        : bonusPlanAmountWithCurrency;

      result.push({
        place: [
          TournamentPlaceEnum.First,
          TournamentPlaceEnum.Second,
          TournamentPlaceEnum.Third
        ][index],
        player: ranking.nickname,
        score: `${ranking?.points ?? '-'}`,
        toWin: toWin
      });
    });

    while (result.length < 3) {
      result.push({
        place: [
          TournamentPlaceEnum.First,
          TournamentPlaceEnum.Second,
          TournamentPlaceEnum.Third
        ][result.length],
        player: '-',
        score: '-',
        toWin: '-'
      });
    }

    if (isDefined(shortProfile)) {
      const currentUserRanking = sortedRankings.find(r => r.partyId === Number(shortProfile?.partyId ?? 0));
      if (currentUserRanking) {
        const award = findTournamentAwardForRanking(tournament, currentUserRanking.ranking);

        const bonusPlanAmountWithCurrency = isDefined(award?.bonusPlan?.bonusAmount)
          ? `${award?.bonusPlan.bonusAmount} SC`
          : isDefined(award?.loyaltyPoints)
            ? `${award?.loyaltyPoints} LP`
            : '-';
        const toWin = award?.bonusPlan?.additionalAward?.amountWithCurrency
          ? `${award?.bonusPlan?.additionalAward?.amountWithCurrency} + ${bonusPlanAmountWithCurrency}`
          : bonusPlanAmountWithCurrency;

        result.push({
          place: TournamentPlaceEnum.CurrentPlace,
          player: currentUserRanking.nickname,
          score: `${currentUserRanking?.points ?? '-'}`,
          toWin: toWin
        });
      } else {
        result.push({
          place: TournamentPlaceEnum.CurrentPlace,
          player: '-',
          score: '-',
          toWin: '-'
        });
      }
    } else {
      result.push({
        place: TournamentPlaceEnum.CurrentPlace,
        player: '-',
        score: '-',
        toWin: '-'
      });
    }

    return result;
  }
);


