export const isEmpty = (value: any): boolean => {
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true; // If it's an empty object, return true
  }

  switch (value) {
    case '':
    case 0:
    case '0':
    case null:
    case false:
    case undefined: {
      return true;
    }
    default: {
      return false;
    }
  }
};
