import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const useRestoreScroll = () => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0 })
    } else {
      window.scrollTo({ top: 0 });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return ref;
};
