import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { type GameWinnersState, type GameWinner, gameWinnersSlice } from '@entities/game-winners';
import { type GamesState, type GameCategory, type GamesItem, gamesSlice } from '@entities/games';
import { GameWinnerItem } from './types';
import { createSelector } from '@reduxjs/toolkit';

type RootState = {
  [gamesSlice.name]: GamesState;
  [gameWinnersSlice.name]: GameWinnersState;
};

const selectAllWinners = (state: RootState): Array<GameWinner> => (
  state[gameWinnersSlice.name].winners
);

const selectAllCategories = (state: RootState): Array<GameCategory> => (
  state[gamesSlice.name].categories
);

export const selectGameWinners = () =>
  createSelector(
    [selectAllCategories, selectAllWinners],
    (categories: Array<GameCategory>, winners: Array<GameWinner>): Array<GameWinnerItem> => {
      let allGames: Array<GamesItem> = [];
      categories.forEach((category: GameCategory) => {
        allGames = [...allGames, ...category.games];
      });

      return winners
      .map((winner: GameWinner) => {
        const game: Maybe<GamesItem> = allGames.find((game: GamesItem) => game.gameId === winner.gameId);
        return {
          amountWon: winner.amountWon,
          gameId: game?.id ?? 0,
          playerName: winner.playerName,
          gameBannerUrl: game?.bannerUrl,
          gameName: game?.name,
          currency: winner.currency,
        };
      })
      .filter((winner: GameWinnerItem) => winner.gameId !== 0);
    }
  );

export const useGameWinnersSelector: TypedUseSelectorHook<RootState> = useSelector;
