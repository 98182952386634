import {
  ComponentType,
  useState
} from 'react';
import Cookies from 'js-cookie';
import {
  useDispatch
} from 'react-redux';
import {
  Typography
} from '@mui/joy';
import {
  SingInPromoBanner
} from '@entities/page-layout';
import {
  Link
} from '@shared/ui';
import {
  isDefined,
  useQueryParamModalVisibility,
  useModalQueryParam,
  useQueryParamValue
} from '@shared/lib';
import {
  useLazyGetMyFavoriteGamesFromOmegaQuery
} from '@entities/games';
import {
  type Consent,
  setSignedInData as setSignedInDataToStore,
  useLazyGetConsentsQuery,
  ConfirmConsentsModal
} from '@entities/session';
import {
  SignInByEmailForm,
  SignInByPhoneForm,
  type SignedInData,
} from '@features/sign-in';
import {
  type SignUp,
  openConfirmationSignUpModal,
  setPropsToSignUp
} from '@features/sign-up';
import {
  useLazyGetPromotionsCategoriesQuery
} from '@features/promotions-filter-by-category';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  ConfirmationAuthViaSocialsModal,
  SocialLogin,
  SuccessConfirmationAuthViaSocialsModal
} from '@features/authentication-via-socials';
import {
  ResetPasswordLink
} from '@features/reset-password';
import {
  AuthModal
} from '@widgets/auth-modal';
import {
  useLazyGetPromotionCardsQuery
} from '@pages/promotions';

// eslint-disable-next-line operator-linebreak
export const withSignInModal =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
    (props: T) => {
      const dispatch = useDispatch();

      const { toggleModal, closeModal } = useModalQueryParam();
      const { sendDataToGtm } = useSendDataToGtm();
      const isOpenSignInModal = useQueryParamModalVisibility('login');
      const { value: categoryKey } = useQueryParamValue('category');

      const [consentsToConsent, setConsentsToConsent] = useState<Maybe<Array<Consent>>>(null);
      const [signedInData, setSignedInData] = useState<Maybe<SignedInData>>(null);
      const [passedError, setPassedError] = useState<Maybe<string>>(null);

      const [getMyFavoriteGamesFromOmega] = useLazyGetMyFavoriteGamesFromOmegaQuery();
      const [getPromotionCards] = useLazyGetPromotionCardsQuery()
      const [getPromotionCategories] = useLazyGetPromotionsCategoriesQuery()
      const [getConsents] = useLazyGetConsentsQuery();

      const handleClose = (): void => {
        closeModal('login');
      };

      const handleSignUp = (): void => {
        toggleModal('login', 'sign-up');
        sendDataToGtm({
          dataLayer: {
            event: 'registration_click',
          }
        });
      };

      const handleSignedIn = async (signedInData: SignedInData): Promise<void> => {
        const { sessionKey } = signedInData;
        const { data: consentsData } = await getConsents(sessionKey);

        if (consentsData?.some(({ consented, consentVersionId }) => !consented && consentVersionId !== 14)) {
          setConsentsToConsent(consentsData.filter(({ consented }) => !consented));
          setSignedInData(signedInData);
        } else {
          dispatch(setSignedInDataToStore(signedInData))
          setSignedInData(null);
          await getMyFavoriteGamesFromOmega();
        }
        getPromotionCards(categoryKey ?? '');
        getPromotionCategories();
        closeModal('login');

        Cookies.set('authToken', sessionKey, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      };

      const handleConfirmedConsent = async (): Promise<void> => {
        if (signedInData) {
          dispatch(setSignedInDataToStore(signedInData))
          setSignedInData(null);
          await getMyFavoriteGamesFromOmega();
        }
      };

      const handleNotCompletedSignUpError = (props: unknown): void => {
        closeModal('login');
        dispatch(setPropsToSignUp(props as SignUp));
        dispatch(openConfirmationSignUpModal());
      };

      const handlePassedError = (error: Maybe<string>): void => {
        if (isDefined(error)) {
          setPassedError('DUPLICATE_VIA_SOCIALS');
        } else {
          setPassedError(null);
        }
      };

      const handleClearPassedError = (): void => {
        setPassedError(null);
      };

      const handleSocialSignUp = (method: string): void => {
        sendDataToGtm({
          dataLayer: {
            event: 'registration_start',
            method: method
          }
        });
      };

      const handleConfirmAuth = (method: string): void => {
        sendDataToGtm({
          dataLayer: {
            event: 'registration_success',
            method: method
          }
        });
      }

      return (
        <>
          <WrappedComponent {...props} />
          <AuthModal
            open={isOpenSignInModal}
            onClose={handleClose}
            titleSlot={
              <Typography
                level='h3'
                fontSize='1.25rem'>
                Login
              </Typography>
            }
            headingSlot={
              <SocialLogin
                signInMode
                onSocialSignUp={handleSocialSignUp}
                onSignedIn={handleSignedIn}
                onError={handlePassedError}
              />
            }
            bannerSlot={<SingInPromoBanner />}
            emailForm={(
              <SignInByEmailForm
                onSignedIn={handleSignedIn}
                onNotCompletedSignUpError={handleNotCompletedSignUpError}
                resetPasswordSlot={<ResetPasswordLink />}
                passedError={passedError}
                onClearPassedError={handleClearPassedError}
              />
            )}
            phoneForm={(
              <SignInByPhoneForm
                onSignedIn={handleSignedIn}
                onNotCompletedSignUpError={handleNotCompletedSignUpError}
                resetPasswordSlot={<ResetPasswordLink />}
                passedError={passedError}
                onClearPassedError={handleClearPassedError}
              />
            )}
            bottomSlot={
              <Typography textAlign='center' color='neutral'>
                Don’t have an account?&nbsp;
                <Link
                  color='success'
                  underline='none'
                  onClick={handleSignUp}>
                  Sign up
                </Link>
              </Typography>
            }
          />
          {Boolean(signedInData) ? (
            <ConfirmConsentsModal
              open={Boolean(signedInData)}
              sessionKey={signedInData?.sessionKey ?? ''}
              consents={consentsToConsent ?? []}
              onConfirm={handleConfirmedConsent}
            />
          ) : null}
          <ConfirmationAuthViaSocialsModal onAuthConfirmation={handleConfirmAuth} />
          <SuccessConfirmationAuthViaSocialsModal />
        </>
      );
    };
