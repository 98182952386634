import { FC, useState, useEffect, useMemo } from 'react';
import { useDropzone, FileRejection, FileError } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { Box, Button, Stack, Typography } from '@mui/joy';
import { isEmpty } from '@shared/lib';
import { Dropdown, useToaster } from '@shared/ui';
import { OmegaApiResponse, OmegaApiResponseStatusEnum } from '@shared/api';
import {
  fetchDocumentTypesAndUserDocuments,
  useLazyUploadUserDocumentQuery,
} from '../api';
import {
  useDocumentsSelector,
  type DocumentType,
  selectDocumentTypes,
} from '../model';
import { UserDocumentsList } from './user-documents-list';

export const UploadDocumentsForm: FC = () => {
  const dispatch =
    useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const { error } = useToaster();

  const documentTypes = useDocumentsSelector(selectDocumentTypes);
  const [uploadUserDocument, { isFetching: isLoading }] = useLazyUploadUserDocumentQuery();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'application/pdf': [],
    },
    maxSize: 1048576,
    multiple: false,
    disabled: isLoading,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
        setFileUploadErrors([]);
      } else {
        setFile(null);
        setFileUploadErrors(
          fileRejections[0]?.errors?.map(
            (error: FileError) => error.message
          ) ?? ['Uploading error']
        );
      }
    },
    onError: (error: Error) => {
      setFile(null);
      setFileUploadErrors([`Uploading error: ${error.message}`]);
    },
  });

  const [selectedDocumentType, setSelectedDocumentType] = useState<Maybe<string>>(null);
  const [file, setFile] = useState<Maybe<File>>(null);
  const [fileUploadErrors, setFileUploadErrors] = useState<Array<string>>([]);

  const fileName: string = useMemo(() => {
    if (!file) return '';
    if (file.name.length <= 28) return file.name;

    let name = file.name.slice(0, file.name.lastIndexOf('.'));
    let extension = file.name.slice(file.name.lastIndexOf('.'));

    return name.slice(0, 15) + '...' + name.slice(name.length - 6) + extension;
  }, [file]);

  const fileSize: string = useMemo(() => {
    if (!file) return '';
    return `${(file.size / 1024).toFixed(0)} KB`;
  }, [file]);

  useEffect(() => {
    dispatch(fetchDocumentTypesAndUserDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (documentTypes.length > 0 && !selectedDocumentType) {
      setSelectedDocumentType(documentTypes[0]?.documentType)
    }
  }, [documentTypes, selectedDocumentType]);

  const handleSelectDocumentType = (value: string): void => {
    setSelectedDocumentType(value);
  };

  const handleUploadDocument = async (): Promise<void> => {
    if (!isEmpty(fileUploadErrors) || !file || !selectedDocumentType) return;

    const { data: uploadUserDocumentResponse } = await uploadUserDocument({
      documentType: selectedDocumentType,
      file,
    });

    const { status, errors, message } =
      uploadUserDocumentResponse as OmegaApiResponse;
    if (status === OmegaApiResponseStatusEnum.ValidationFail) {
      setFileUploadErrors(
        !isEmpty(errors) ? errors!.map((error) => error.error) : ['Omega error']
      );
      return;
    }

    if (status !== OmegaApiResponseStatusEnum.Success) {
      error({ message: message ?? 'Something went wrong' });
    }

    if (status === OmegaApiResponseStatusEnum.Success) {
      setFile(null);
    }
  };

  return (
    <Stack
      direction='row'
      gap={3}
      sx={({ breakpoints }) => ({
        width: '100%',
        [breakpoints.down(1129)]: {
          flexDirection: 'column',
        },
      })}>
      <Stack
        direction='column'
        gap={2}
        sx={({ breakpoints }) => ({
          minWidth: 358,
          [breakpoints.down(1129)]: {
            minWidth: '100%',
          },
        })}>
        <Dropdown
          label='Choose document type'
          value={selectedDocumentType}
          onChange={handleSelectDocumentType}
          disabled={isLoading}
          options={documentTypes.map((documentType: DocumentType) => ({
            label: documentType.documentType.replace('_', ' '),
            value: documentType.documentType,
          }))}
          inputProps={{
            root: {
              sx: {
                minHeight: 'unset',
              },
            },
            label: {
              sx: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              },
            },
          }}
        />

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={({ palette, breakpoints }) => ({
            minHeight: 176,
            borderRadius: 8,
            borderStyle: 'dashed',
            borderWidth: 2,
            borderColor: isEmpty(fileUploadErrors)
              ? palette.common[700]
              : palette.common.error,
            backgroundColor: palette.common[475],
            gap: 1.5,
            [breakpoints.down(768)]: {
              minHeight: 80,
            },
          })}
          {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />

          {file && isEmpty(fileUploadErrors) && (
            <Box
              component='img'
              loading='lazy'
              src='/assets/webp/green-picture.webp'
              alt='Image'
              sx={{
                height: 24,
                width: 24,
              }} />
          )}

          <Stack direction='column'>
            {isEmpty(fileUploadErrors) ? (
              <>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: file ? 400 : 500,
                    lineHeight: '24px',
                    color: file
                      ? palette.common.white
                      : palette.common[700],
                  })}>
                  {file ? fileName : 'Upload a file'}
                </Typography>

                <Typography
                  sx={({ palette }) => ({
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: palette.common[150],
                  })}>
                  {file ? fileSize : 'PNG, JPG, PDF up to 1 MB'}
                </Typography>
              </>
            ) : (
              fileUploadErrors.map((error: string, key: number) => (
                <Typography
                  key={key}
                  sx={({ palette }) => ({
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: palette.common.error,
                  })}>
                  {error}
                </Typography>
              ))
            )}
          </Stack>
        </Stack>

        <Button
          variant='solid'
          color='primary'
          loading={isLoading}
          disabled={!isEmpty(fileUploadErrors) || !file}
          onClick={handleUploadDocument}>
          Confirm
        </Button>
      </Stack>

      <UserDocumentsList />
    </Stack>
  );
};
