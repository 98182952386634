import { Middleware } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { signOut } from '@entities/session';
import { closeSidebarMenu } from '@widgets/sidebar-menu';
import { appRouter } from '@app/app-router';

export const notLoggedInErrorHandler: Middleware =
  ({ dispatch }) => (next) => (action: any) => {
    if (action?.payload?.status === OmegaApiResponseStatusEnum.NotLoggedIn) {
      dispatch(signOut());
      dispatch(closeSidebarMenu());
      Cookies.remove('authToken', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      appRouter.navigate('/?login=true');
    }
    return next(action);
  };
