import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchTournaments, tournamentsApiSlice } from '../api';
import {
  type Tournament,
  type TournamentsState,
} from './types';

const initialState: TournamentsState = {
  isLoading: true,
  publicTournaments: [],
  tournamentRankings: [],
};

export const tournamentsSlice = createSlice({
  name: 'tournaments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        fetchTournaments.pending,
        (state: TournamentsState) => {
          state.isLoading = true
        }
      )
      .addCase(
        fetchTournaments.fulfilled,
        (state: TournamentsState, action: PayloadAction<Array<Tournament>>) => {
          state.isLoading = false;
          state.publicTournaments = [...action.payload]
            .sort((a, b) =>
              new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
            );
        }
      );
    builder.addMatcher(
      tournamentsApiSlice.endpoints.getPublicTournaments.matchFulfilled,
      (state, action) => {
        state.publicTournaments = action.payload;
      });
    builder.addMatcher(
      tournamentsApiSlice.endpoints.getTournamentRanking.matchFulfilled,
      (state, action) => {
        state.tournamentRankings = action.payload;
      });
  },
});

export const tournamentsReducer = tournamentsSlice.reducer;
