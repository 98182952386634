import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { type Tournament, TournamentStatusEnum } from '../model';
import { tournamentsApiSlice } from './slice';

export const fetchTournaments = createAsyncThunk<Array<Tournament>, boolean>(
  'fetch-tournaments',
  async (isAuth: boolean, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      dayjs.extend(utc);

      const brandId = 1;
      const endDate = dayjs()
        .add(14, 'days')
        .format('YYYY-MM-DD');

      const queryParams = new URLSearchParams({
        endDate: endDate,
        brandId: brandId.toString()
      });
      queryParams.append('status_in', TournamentStatusEnum.Active);
      queryParams.append('status_in', TournamentStatusEnum.Pending);

      const baseEndpoint = isAuth
        ? tournamentsApiSlice.endpoints.getPrivateTournaments
        : tournamentsApiSlice.endpoints.getPublicTournaments

      const result = await dispatch(baseEndpoint
        .initiate({ queryParams: queryParams.toString() }))
        .unwrap();

      if (!result)
        return rejectWithValue(new Error('Fail to fetch tournaments'));

      return fulfillWithValue(result);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
