import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { baseApi, baseOmegaApi } from '@shared/api';
import { circularLoaderSlice } from '@shared/ui/circular-loader';
import { featureToggles } from '@shared/config';
import { sessionSlice } from '@entities/session';
import { messagesSlice } from '@entities/messages';
import { pageLayoutSlice } from '@entities/page-layout';
import { gamesSlice } from '@entities/games';
import { coinPackagesSlice } from '@entities/coin-packages';
import { tournamentsSlice } from '@entities/tournaments';
import { transactionsSlice } from '@entities/transactions';
import { gameWinnersSlice } from '@entities/game-winners';
import { challengesSlice } from '@entities/challenges';
import { signUpSlice } from '@features/sign-up';
import {
  managePromotionalSubscriptionsSlice
} from '@features/manage-promotional-subscriptions';
import { redeemCoinsSlice } from '@features/redeem-coins';
import { documentsSlice } from '@features/upload-documents';
import { resetPasswordSlice } from '@features/reset-password';
import {
  transactionFilterByTypeSlice
} from '@features/transaction-filter-by-type';
import { checkTrackingCodesSlice } from '@features/check-tracking-codes';
import { authViaSocialsSlice } from '@features/authentication-via-socials';
import { 
  promotionsFilteredByCategoriesSlice 
} from '@features/promotions-filter-by-category';
import { sidebarMenuSlice } from '@widgets/sidebar-menu';
import { notificationsSidebarSlice } from '@widgets/notifications-sidebar';
import { transactionListSlice } from '@widgets/transaction-list';
import { homePageSlice } from '@pages/home';
import { gamesPageSlice } from '@pages/games';
import { tournamentsPageSlice } from '@pages/tournaments';
import { dynamicPageSlice } from '@pages/dynamic';
import { promotionsPageSlice } from '@pages/promotions';
import { accountPageSlice } from '@pages/account';
import { luxeClubPageSlice } from '@pages/luxe-club';
import { tournamentDetailsPageSlice } from '@pages/tournament-details';
import { promotionDetailsPageSlice } from '@pages/promotion-details';
import { challengesPageSlice } from '@pages/challenges';
import { playGamePageSlice } from '@pages/play-game';
import { notLoggedInErrorHandler } from '@app/middlewares';
import { rootReducer } from './root-reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    circularLoaderSlice.name,
    pageLayoutSlice.name,
    sidebarMenuSlice.name,
    notificationsSidebarSlice.name,
    homePageSlice.name,
    sessionSlice.name,
    signUpSlice.name,
    gamesSlice.name,
    tournamentsSlice.name,
    gamesPageSlice.name,
    tournamentsPageSlice.name,
    dynamicPageSlice.name,
    promotionsPageSlice.name,
    messagesSlice.name,
    accountPageSlice.name,
    managePromotionalSubscriptionsSlice.name,
    documentsSlice.name,
    resetPasswordSlice.name,
    coinPackagesSlice.name,
    transactionsSlice.name,
    transactionListSlice.name,
    redeemCoinsSlice.name,
    gameWinnersSlice.name,
    luxeClubPageSlice.name,
    transactionFilterByTypeSlice.name,
    checkTrackingCodesSlice.name,
    tournamentDetailsPageSlice.name,
    authViaSocialsSlice.name,
    promotionDetailsPageSlice.name,
    ...(featureToggles.challenges ? [
      challengesSlice.name,
      challengesPageSlice.name,
    ] : []),
    playGamePageSlice.name,
    promotionsFilteredByCategoriesSlice.name
  ],
};

const reducer = persistReducer(persistConfig, rootReducer);

const makeStore = () => {
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          ignoredPaths: [/omegaApi.queries.uploadUserDocument/],
        },
      })
        .concat(baseApi.middleware)
        .concat(baseOmegaApi.middleware)
        .concat(notLoggedInErrorHandler)
  });
  setupListeners(store.dispatch);
  return store;
};

export const store = makeStore();

export const persistor = persistStore(store);

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>;
