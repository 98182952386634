import * as Yup from 'yup';

const emailRegex: RegExp = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const emailString = Yup.string()
  .required('Email is a required field')
  .matches(emailRegex, 'Enter a valid email');

export const emailSchema = Yup.object().shape({
  email: emailString
});
