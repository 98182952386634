import compose from 'compose-function';
import { withSignInModal } from './with-sign-in-modal';
import { withSignUpModal } from './with-sign-up-modal';
import { withResetPasswordModal } from './with-reset-password-modal';
import { withMessagesModal } from './with-messages-modal';
import { withBuyModal } from './with-buy-modal';
import { withCoinRedemptionPopup } from './with-coin-redemption-popup';

export const withModals = compose(
  withSignInModal,
  withSignUpModal,
  withResetPasswordModal,
  withMessagesModal,
  withBuyModal,
  withCoinRedemptionPopup,
);