import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc'

export const useCountdown = (date: Dayjs | string) => {
  const [timeArray, setTimeArray] = useState<Maybe<Array<number>>>(null);

  useEffect(() => {
    if (!timeArray) {
      const calculateTimeStamp = () => {
        dayjs.extend(utc);
        const now = dayjs();
        const tournamentDate = dayjs.utc(date);
        if (now.isAfter(tournamentDate)) {
          clearInterval(interval);
          setTimeArray(null);
          return;
        }
        const newArray: Array<number> = [];
        newArray.push(tournamentDate.diff(now, 'days'));
        newArray.push(tournamentDate.diff(now, 'hours') % 24);
        newArray.push(tournamentDate.diff(now, 'minute') % 60);
        newArray.push(tournamentDate.diff(now, 'second') % 60);

        setTimeArray(newArray);
      }

      const interval = setInterval(() => {
        calculateTimeStamp();
      }, 1000);
      calculateTimeStamp();
    }
  }, [timeArray, date]);


  return timeArray;
};
