import { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Stack,
  Typography
} from '@mui/joy';
import {
  TextInput,
  Button,
  Checkbox,
  PhoneInput
} from '@shared/ui';
import { useModalQueryParam } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import {
  type OmegaApiResponse,
  OmegaApiResponseStatusEnum,
  omegaErrorsMapper,
} from '@shared/api';
import { signUpByPhoneSchema } from '../lib';
import { useLazyQuickSignUpQuery } from '../api';
import {
  type SignUp,
  openConfirmationSignUpModal,
  setPropsToSignUp
} from '../model';
import { CheckboxFormLabel } from './checkbox-from-label.component';
import { RestrictionAreasLink } from './restriction-areas-link.component';
import { TermsOfUseLink } from './terms-of-use-link.component';
import { PrivacyPolicyLink } from './privacy-policy-link.component';

export type SignUpByPhoneFormProps = {
  trackingCodes?: Maybe<string>;
  consentVersionIds?: Maybe<Array<number>>;
  passedError?: Maybe<string>;
  onClearPassedError?(): void;
  onSignUp(method: string): void;
};

export const SignUpByPhoneForm: FC<SignUpByPhoneFormProps> = ({
  trackingCodes,
  consentVersionIds,
  passedError,
  onClearPassedError,
  onSignUp
}) => {
  const dispatch = useDispatch();
  const { closeModal } = useModalQueryParam();
  const [quickSignUp, { isFetching, data }] = useLazyQuickSignUpQuery();

  const [hasLostFocus, setHasLostFocus] = useState<boolean>(false);
  const {
    control,
    formState,
    setValue,
    trigger,
    setError,
    watch,
    clearErrors,
    setFocus,
    handleSubmit
  } = useForm({
    resolver: yupResolver(signUpByPhoneSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      mobile: '',
      password: '',
      ageAndStateConfirmation: false,
      termsAndPrivacyConfirmation: false,
      smsSubscriptionConfirmation: false
    },
  });

  useEffect(() => {
    if (passedError) {
      setError('root.serverError', {
        type: passedError,
        message: omegaErrorsMapper['SIGN-UP'][passedError]
      });
    }
    else {
      clearErrors('root.serverError');
    }
    // eslint-disable-next-line
  }, [passedError]);

  const handleSingUp = async (
    formData: { mobile: Phone; password: Password; },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();

    const propsToSignUp: SignUp = {
      ...formData,
      currency: CurrencyCodesEnum.SCO,
      secondaryCurrencies: CurrencyCodesEnum.GCO,
      verificationTarget: 'mobile',
      country: 'US',
      ...(trackingCodes && ({
        extraInfo: trackingCodes
      })),
      ...(consentVersionIds && {
        consentedVersions: !watch('smsSubscriptionConfirmation')
          ? consentVersionIds.filter((id) => id !== 14)
          : consentVersionIds
      })
    };

    const { data } = await quickSignUp(propsToSignUp)
    const { status, errors, message } = data as OmegaApiResponse;

    if (status === OmegaApiResponseStatusEnum.Fail) {
      const { fieldName, type, errorMessage }: any = message?.includes('already exists')
        ? { fieldName: 'mobile', type: 'DUPLICATE', errorMessage: omegaErrorsMapper['SIGN-UP']['DUPLICATE'] }
        : { fieldName: 'root.serverError', type: '', errorMessage: message };
      setError(fieldName, { type, message: errorMessage });
      setFocus(fieldName);
      return;
    }

    if (status === OmegaApiResponseStatusEnum.ValidationFail) {
      const { field, error } = errors?.[0] as any;
      const fieldName: any = field === 'mobile' ? field : 'root.serverError';
      setError(
        fieldName,
        {
          type: error,
          message: omegaErrorsMapper['SIGN-UP'][error]
        });
      setFocus(fieldName);
      return;
    }

    onSignUp('phone');

    dispatch(setPropsToSignUp(propsToSignUp));
    dispatch(openConfirmationSignUpModal());
    closeModal('sign-up');
  };

  const handlePhoneValueChange = async (phone: Phone): Promise<void> => {
    setValue('mobile', phone);
    if (hasLostFocus) await trigger('mobile');
  };

  const handlePhoneBlur = async (): Promise<void> => {
    setHasLostFocus(true);
    await trigger('mobile');
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(handleSingUp)}>
      {formState?.errors?.root?.serverError && (
        <Typography
          sx={({ colorSchemes }) => ({
            color: colorSchemes.dark.palette.common.error,
            fontWeight: 500,
            marginBottom: 2,
          })}>
          {formState?.errors?.root?.serverError?.message}
          {data?.message}
        </Typography>
      )}
      <Stack
        direction='column'
        rowGap={0.5}
        mb={1.5}>
        <PhoneInput
          name='mobile'
          label='Phone Number'
          defaultCountry='us'
          disabled={isFetching}
          onBlur={handlePhoneBlur}
          onChange={handlePhoneValueChange}
          error={formState.errors?.mobile?.message}
        />
        <Controller
          disabled={isFetching}
          name='password'
          control={control}
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Password'
              type='password'
              placeholder='Enter your password'
              disabled={isFetching}
              value={value}
              onBlur={onBlur}
              onChange={(args) => {
                onChange(args);
                onClearPassedError?.();
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </Stack>
      <Stack
        direction='column'
        gap={1}
        mb={2}>
        <Stack direction='row' gap={1}>
          <Controller
            disabled={isFetching}
            name='ageAndStateConfirmation'
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <Checkbox
                disabled={isFetching}
                checked={value}
                onBlur={onBlur}
                onChange={onChange}
                sx={{
                  textAlign: 'justify',
                  gap: 1,
                  height: 36
                }}
              />
            )}
          />
          <CheckboxFormLabel>
            I am at least 18 years old and I am not a resident of the&nbsp;<RestrictionAreasLink />
          </CheckboxFormLabel>
        </Stack>
        <Stack direction='row' gap={1}>
          <Controller
            disabled={isFetching}
            name='termsAndPrivacyConfirmation'
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <Checkbox
                disabled={isFetching}
                checked={value}
                onBlur={onBlur}
                onChange={onChange}
                sx={{
                  textAlign: 'justify',
                  gap: 1,
                  height: 36
                }}
              />
            )}
          />
          <CheckboxFormLabel>
            I accept the SweepLuxe&nbsp;
            <TermsOfUseLink />
            &nbsp;and the&nbsp;
            <PrivacyPolicyLink />
          </CheckboxFormLabel>
        </Stack>
        <Stack direction='row' gap={1}>
          <Controller
            disabled={isFetching}
            name='smsSubscriptionConfirmation'
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <Checkbox
                disabled={isFetching}
                checked={value}
                onBlur={onBlur}
                onChange={onChange}
                sx={{
                  textAlign: 'justify',
                  gap: 1,
                  height: 36
                }}
              />
            )}
          />
          <CheckboxFormLabel>
            I give consent to receive SMS 
            messages. Message and data rates 
            may apply. Reply STOP to opt out
          </CheckboxFormLabel>
        </Stack>
      </Stack>
      <Button
        disabled={
          !formState.isValid ||
          isFetching
        }
        loading={isFetching}
        fullWidth
        type='submit'>
        Sign up
      </Button>
    </Box>
  );
};
