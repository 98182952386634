import * as Yup from 'yup';
import dayjs from 'dayjs';
import { phoneString, emailString } from '@shared/lib';

export const editFullProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  nickname: Yup.string().required('Nickname is required'),
  email: emailString,
  phone: phoneString,
  birthDate: Yup.date()
    .typeError('Date of birth is required')
    .max(dayjs().subtract(21, 'year').toDate(), 'You must be at least 21 years old to participate in gambling')
    .required('Date of birth is required'),
  address: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  province: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  postalCode: Yup.string().required('ZIP is required'),
});