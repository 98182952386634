import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import {
  Stack,
  Box,
  Skeleton,
  AspectRatio,
  Tooltip
} from '@mui/joy';
import { DicesIcon, IconButton } from '@shared/ui';
import {
  selectToggledCurrencyCode,
  selectCurrencySessionKey,
  selectIsAuthenticated,
  useLazyBalanceQuery
} from '@entities/session';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  useLazyGetGameQuery
} from '../api';
import { mapGameUrl } from '../lib';
import { PlayGameIframe } from './play-game-iframe.component';
import { FullScreenToggle } from './full-screen-toggle.component';
import { selectIsFullScreen, usePlayGamePageSelector } from '../model';

export const PlayGamePage: FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currencySessionKey = useSelector(selectCurrencySessionKey);
  const currencyCode = useSelector(selectToggledCurrencyCode);
  const isFullScreen = usePlayGamePageSelector(selectIsFullScreen);

  const [getGame, { data: gameData, isFetching }] = useLazyGetGameQuery();
  const [getBalance] = useLazyBalanceQuery();

  const { sendDataToGtm } = useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'play-game'
    }
  });

  const [gameUrl, setGameUrl] = useState<string>('');
  const [iframeLoading, setIframeLoading] = useState<boolean>(true);

  const fetchGameInfo = useCallback(async () => {
    const id = Number(params.id);
    if (!id) return;
    await getGame(id);
  }, [getGame, params?.id]);

  const handleLoad = () => {
    setIframeLoading(false);
    sendDataToGtm({
      dataLayer: {
        event: 'game_launch',
        gameId: params.id,
        gameMode: currencyCode === 'SCO'
          ? 'SC'
          : 'GC'
      }
    });
  };

  const handleClose = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    navigate(-1);
  };

  const handleGoToLobby = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    navigate('/games');
  }

  useLayoutEffect(() => {
    fetchGameInfo();
    return () => {
      if (isAuthenticated) getBalance();
    };
  }, [fetchGameInfo, getBalance, isAuthenticated]);

  useEffect(() => {
    if (gameData && currencySessionKey) {
      setIframeLoading(true);
      const url = mapGameUrl({
        gameId: gameData.gameId,
        platform: gameData.productCode,
        sessionKey: currencySessionKey,
        currencyCode: currencyCode,
        lobbyURL: window.location.origin
      });
      setGameUrl(url);
    }
  }, [gameData, currencySessionKey, currencyCode]);

  return (
    <Box
      sx={({ breakpoints }) => ({
        maxWidth: isFullScreen ? '100%' : '1440px',
        width: '100%',
        height: '100%',
        paddingBlock: '3rem',
        paddingInline: isFullScreen ? '10rem' : '3rem',
        [breakpoints.down(769)]: {
          padding: 0,
        },
        '@media (orientation: landscape) and (max-height: 500px)': {
          padding: 0,
        }
      })}>
      <Stack
        direction='row'
        alignItems='flex-start'
        justifyContent="center"
        gap='0.5rem'
        height='100%'
        width='100%'>
        <AspectRatio
          ratio='16/9'
          sx={({ breakpoints }) => ({
            overflow: 'hidden',
            margin: '-2px',
            height: '100%',
            aspectRatio: '16/9',
            [breakpoints.up(769)]: {
              maxHeight: isFullScreen ? '100%' : '755px',
              borderRadius: '0.5rem',
              overflow: 'hidden',
            },
            [breakpoints.down(769)]: {
              display: 'flex',
            },
            '@media (orientation: landscape) and (max-height: 500px)': {
              display: 'flex',
              borderRadius: 8,
              overflow: 'unset',
              width: '100%',
              aspectRatio: 'unset',
              alignItems: 'flex-start',
              '& .MuiAspectRatio-content': {
                width: '100%',
                pb: 0,
              }
            }
          })}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              width: '100%',
            }}>
            <Skeleton
              variant='overlay'
              loading={iframeLoading || isFetching}
              sx={{
                visibility: (iframeLoading || isFetching)
                  ? 'visible'
                  : 'hidden',
              }}
            />
            {(!isFetching || !iframeLoading) && (
              <PlayGameIframe
                onLoad={handleLoad}
                key={gameUrl}
                src={gameUrl}
                sx={{
                  visibility: isFetching
                    ? 'hidden'
                    : 'visible',
                }}
              />
            )}
          </Box>
        </AspectRatio>
        <Stack>
          <IconButton
            size='md'
            onClick={handleClose}
            sx={({ breakpoints }) => ({
              '@media (orientation: landscape) and (max-height: 500px)': {
                display: 'none',
              },
              [breakpoints.down(769)]: {
                display: 'none',
              },
            })}>
            <Close />
          </IconButton>
          <FullScreenToggle />
          <Tooltip 
            title="More Games" 
            placement="right" 
            sx={({ palette }) => ({
              backgroundColor: palette.neutral.outlinedBorder,
              paddingBlock: '8px',
              paddingInline: '12px',
              fontSize: 12,
              fontWeight: 400,
              lineHeight: '18px',
              borderRadius: 8,
            })}>
            <IconButton
              size='md'
              onClick={handleGoToLobby}
              sx={({ breakpoints }) => ({
                '@media (orientation: landscape) and (max-height: 500px)': {
                  display: 'none',
                },
                [breakpoints.down(769)]: {
                  display: 'none',
                },
              })}>
              <DicesIcon sx={{ fontSize: '32px' }}/>
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Box>
  );
};
