import { styled } from '@mui/joy';

export const PlayGameIframe = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '100%',
  border: 'none',
  [theme.breakpoints.down(769)]: {
    width: '100vw',
  },
  '@media(orientation: landscape) and (max-height: 500px)': {
    width: '100%',
    height: 'calc(100vh - 4.25rem)'
  }
}));
