import { FC, useMemo } from 'react';
import { Sheet, Stack, Table } from '@mui/joy';
import { useTheme, useMediaQuery } from '@mui/material';
import { isEmpty } from '@shared/lib';
import { type Transaction } from '../model';
import {
  getCurrencyByTransactionType,
  getLabelForTransactionType
} from '../lib';
import {
  TransactionCard
} from './transaction-card.component';
import {
  NoTransactionsLabel
} from './no-transactions-label.component';

export type TransactionTableProps = {
  transactions: Array<Transaction>;
};

export const TransactionTable: FC<TransactionTableProps> = ({ transactions }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1024));
  const hasNoTransactions = useMemo(() => isEmpty(transactions), [transactions]);
  return isTablet ? (
    <Stack
      direction='column'
      sx={{
        width: '100%',
        gap: 1.25,
        mb: 2
      }}>
      {transactions.map(({
        id,
        dateTime,
        type,
        gameName,
        paymentSubMethod,
        amount,
        currency
      }, index) => (
        <TransactionCard
          key={`${id}-${index}`}
          id={id}
          dateTime={dateTime}
          labelForTransactionType={getLabelForTransactionType(type, currency)}
          gameName={gameName}
          paymentSubMethod={paymentSubMethod}
          amount={`${getCurrencyByTransactionType(type, currency)} ${amount}`}
          currency={currency}
        />
      ))}
      {isEmpty(transactions) && (
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={({ palette }) => ({
            width: '100%',
            height: 220,
            overflow: 'hidden',
            borderRadius: 8,
            background: palette.common[475],
            borderColor: palette.common[925],
            borderWidth: 1,
            borderStyle: 'solid',
          })}>
          <NoTransactionsLabel />
        </Stack>
      )}
    </Stack>
  ) : (
    <Sheet
      sx={({ palette }) => ({
        minHeight: 404,
        width: '100%',
        backgroundColor: palette.common[475],
        borderRadius: 8,
        position: 'relative',
      })}>
      <Table
        size='ml'
        sx={{
          gap: 1.25,
          width: '100%',
          '& thead th:nth-child(1)': {
            width: '30%'
          },
          '& thead th:nth-child(2)': {
            width: '15%'
          },
          '& thead th:nth-child(3)': {
            width: '18%'
          },
          '& thead th:nth-child(4)': {
            width: '22%'
          },
        }}>
        <thead>
          <tr>
            <th>Transaction&nbsp;ID</th>
            <th>Date</th>
            <th>Type</th>
            <th>Product</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactions?.map(({
            id,
            dateTime,
            type,
            gameName,
            paymentSubMethod,
            amount,
            currency
          }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{dateTime}</td>
              <td>{getLabelForTransactionType(type, currency)}</td>
              <td>{gameName ?? paymentSubMethod}</td>
              <td>{getCurrencyByTransactionType(type, currency)} {amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {hasNoTransactions && (
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}>
          <NoTransactionsLabel />
        </Stack>
      )}
    </Sheet>
  );
};