import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import {
  type GetTransactionsResponse,
  transactionsApiSlicer
} from '../api';
import {
  type TransactionState
} from './types';
import { mapTransactions } from '../lib';

const initialState: TransactionState = {
  transactions: [],
  pagination: {
    total: 0,
    page: 1,
    pageSize: 5,
    pageCount: 0,
  }
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    resetPagination: (state: TransactionState) => {
      Object.assign(state.pagination, initialState.pagination);
    },
    moveToPage: (state: TransactionState, action: PayloadAction<number>) => {
      state.pagination.page = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<TransactionState>) => {
    builder.addMatcher(
      transactionsApiSlicer.endpoints.getTransactions.matchFulfilled,
      (state: TransactionState, action: PayloadAction<GetTransactionsResponse>) => {
        if (action.payload.status === OmegaApiResponseStatusEnum.Success) {
          state.transactions = mapTransactions(action.payload.transactionList);

          state.pagination.total = action.payload.totalRecords;
          const totalRecords = action.payload.totalRecords;
          const pageSize = state.pagination.pageSize;
          const pageCount = Math.ceil(totalRecords / pageSize);
          state.pagination.pageCount = Math.max(pageCount, 0);
        }
      });
  },
});

export const {
  moveToPage,
  resetPagination,
} = transactionsSlice.actions;
export const transactionsReducer = transactionsSlice.reducer;
