import {
  baseOmegaApi,
  OmegaApiResponse,
  SESSION_TAG
} from '@shared/api';
import {
  type UpdatePlayerInfo,
} from './types';

export const playerApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    balance: build.query<OmegaApiResponse, void>({
      query: () => ({ url: 'ips/getBalance' }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: true },
    }),
    getPlayerInfo: build.query<OmegaApiResponse, void>({
      query: () => ({ url: 'ips/getPlayerInfo' }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: false },
    }),
    updatePlayerInfo: build.query<OmegaApiResponse, Partial<UpdatePlayerInfo>>({
      query: (params) => ({ url: 'ips/updatePlayerInfo', params }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: false },
    }),
    getLoyaltyPoints: build.query<OmegaApiResponse, void>({
      query: () => ({ url: '/ips/loyaltyPointsStatus' }),
      extraOptions: { auth: true, showLoader: false },
    }),
  }),
});

export const {
  useLazyBalanceQuery,
  useLazyGetPlayerInfoQuery,
  useBalanceQuery,
  useGetPlayerInfoQuery,
  useLazyUpdatePlayerInfoQuery,
  useGetLoyaltyPointsQuery,
} = playerApiSlicer;
