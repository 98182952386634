import { createSlice } from '@reduxjs/toolkit';
import { NotificationsSidebarState } from './types';

const initialState: NotificationsSidebarState = {
  isOpen: false,
};

export const notificationsSidebarSlice = createSlice({
  name: 'notifications-sidebar',
  initialState,
  reducers: {
    openNotificationsSidebar: (state: NotificationsSidebarState) => {
      state.isOpen = true;
    },
    closeNotificationsSidebar: (state: NotificationsSidebarState) => {
      state.isOpen = false;
    },
  },
});

export const notificationsSidebarReducer = notificationsSidebarSlice.reducer;

export const { closeNotificationsSidebar, openNotificationsSidebar } = notificationsSidebarSlice.actions;
