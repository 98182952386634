import { CurrencyCodesEnum } from '@shared/types';

export enum TransactionTypeEnum {
  GameBet = 'GAME_BET',
  GameWin = 'GAME_WIN',
  Withdrawal = 'WITHDRAWAL',
  Deposit = 'DEPOSIT'
};

export type Transaction = {
  id: number;
  dateTime: string;
  type: TransactionTypeEnum;
  gameName: string;
  paymentSubMethod: string;
  amount: number;
  currency: CurrencyCodesEnum;
};

export type TransactionPagination = {
  total: number;
  page: number;
  pageSize: number;
  pageCount: number;
};

export type TransactionState = {
  transactions: Array<Transaction>;
  pagination: TransactionPagination;
};