import {
  FC,
  BaseSyntheticEvent,
  useMemo,
  useEffect
} from 'react';
import {
  Controller,
  useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Modal,
  ModalDialog,
  Stack,
  Typography
} from '@mui/joy';
import {
  IconButton,
  Button,
  CloseIcon,
  TextInput
} from '@shared/ui';
import { isEmpty } from '@shared/lib';
import { coinRedemptionSchema } from '../lib'
import { RedeemableBalance } from './redeemable-balance.component';

export type RedeemPrizeModalProps = {
  open: boolean;
  withdrawableBalance: number;
  bonusBalance: number;
  onClose?(): void;
  onCoinRedeem?(amount: number): void;
};

export const CoinRedemptionPopup: FC<RedeemPrizeModalProps> = ({
  open,
  withdrawableBalance,
  bonusBalance,
  onClose,
  onCoinRedeem,
}) => {
  const {
    control,
    formState,
    watch,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(coinRedemptionSchema(withdrawableBalance)),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { amount: 0 },
  });
  const amount = watch('amount');

  useEffect(() => {
    reset({ amount: 0 });
  }, [open, reset]);

  const usd = useMemo(() => {
    const numericAmount = Number(amount);
    return isEmpty(numericAmount) || numericAmount < 0
      ? 0
      : numericAmount;
  }, [amount]);

  const handleCoinRedeem = async (
    formData: { amount: number },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();
    onCoinRedeem?.(formData.amount);
  };

  const handleClose = (): void => {
    onClose?.();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}>
      <ModalDialog
        layout='center'
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          maxWidth: 358,
          paddingBlock: 3,
          paddingInline: 3,
          backgroundColor: palette.common[475],
          [breakpoints.down(390)]: {
            maxWidth: 'calc(100vw - 2rem)',
          },
        })}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
          <Stack
            direction='row'
            gap='0.5rem'
            alignItems='center'>
            <Typography
              sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: '1.125rem',
                fontWeight: 600,
                lineHeight: 'normal',
              })}>
              Redeem a prize
            </Typography>
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          direction='column'
          component='form'
          onSubmit={handleSubmit(handleCoinRedeem)}>
          <RedeemableBalance
            withdrawableBalance={withdrawableBalance}
            bonusBalance={bonusBalance}
          />
          <Stack
            direction='column'
            gap={.5}
            sx={{
              gap: .5,
              mt: 2,
              mb: 2,
            }}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              gap={2}
              sx={({ palette }) => ({
                '& .MuiFormControl-root': {
                  minHeight: '100%'
                },
                '& .MuiFormLabel-root': {
                  color: palette.common.white
                },
                '& .MuiInput-root.Mui-disabled': {
                  backgroundColor: palette.common[475],
                  color: palette.common.white,
                },
                '& .MuiInput-input': {
                  width: '100%'
                },
                '& .MuiFormHelperText-root': {
                  display: 'none'
                },
                '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '& input[type="number"]': {
                  MozAppearance: 'textfield'
                }
              })}>
              <Controller
                name='amount'
                control={control}
                render={({ field: { value, onBlur, onChange }, fieldState }) => (
                  <TextInput
                    label='Sweep coins'
                    value={value}
                    type='number'
                    onBlur={onBlur}
                    onChange={onChange}
                    error={fieldState.error?.message}
                    sx={({ palette }) => ({
                      '.MuiInput-root': {
                        backgroundColor: palette.common[900],
                      }
                    })}
                  />
                )}
              />
              <TextInput
                disabled
                label='USD'
                value={usd}
              />
            </Stack>
            {!isEmpty(formState.errors.amount) && (
              <Typography
                sx={({ palette }) => ({
                  color: palette.common.error,
                  fontSize: 14,
                  lineHeight: '21px',
                })}>
                {formState.errors.amount?.message}
              </Typography>
            )}
          </Stack>
          <Button
            fullWidth
            color='primary'
            variant='solid'
            type='submit'
            disabled={!formState.isDirty || !formState.isValid}>
            Confirm
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};