import { createSelector } from '@reduxjs/toolkit';
import {
  type ShortProfile,
  selectShortProfile
} from '@entities/session';
import {
  type Tournament,
  type TournamentRanking,
  type TournamentAward,
  selectTournaments,
  selectTournamentRankings,
} from '@entities/tournaments';
import {
  isDefined,
  maskString
} from '@shared/lib';
import { LeaderboardItem } from './types';

const findTournamentAwardForRanking = (
  tournament: Tournament,
  ranking: number
): Maybe<TournamentAward> => {
  return tournament.awards?.find(({ rankStart, rankEnd }) =>
    ranking >= rankStart! && ranking <= rankEnd!
  );
};

export const selectTournamentLeaderboardItems = createSelector(
  selectShortProfile,
  selectTournaments,
  selectTournamentRankings,
  (
    shortProfile: Maybe<ShortProfile>,
    tournaments: Array<Tournament>,
    tournamentRankings: Array<TournamentRanking>
  ): Maybe<Array<LeaderboardItem>> => {
    if (!tournaments.length || !tournamentRankings.length) return null;

    const tournament = tournaments[0];
    const sortedRankings = [...tournamentRankings]
      .sort((a, b) => a.ranking - b.ranking);

    let leaderboardItems: Array<LeaderboardItem> = sortedRankings
      .slice(0, 10)
      .map(ranking => {
        const award = findTournamentAwardForRanking(tournament, ranking.ranking);

        const bonusPlanAmountWithCurrency = isDefined(award?.bonusPlan?.bonusAmount)
          ? `${award?.bonusPlan.bonusAmount} SC`
          : isDefined(award?.loyaltyPoints)
            ? `${award?.loyaltyPoints} LP`
            : '-';
        const toWin = award?.bonusPlan?.additionalAward?.amountWithCurrency
          ? `${award?.bonusPlan?.additionalAward?.amountWithCurrency} + ${bonusPlanAmountWithCurrency}`
          : bonusPlanAmountWithCurrency;

        return {
          rank: ranking.ranking,
          player: maskString(ranking.nickname),
          score: `${ranking?.points ?? '-'}`,
          toWin,
          isCurrentPlace: (ranking?.partyId ?? 0) === Number(shortProfile?.partyId ?? 0)
        };
      });

    const isCurrentUserIncluded = leaderboardItems.some(item => item.isCurrentPlace);
    if (!isCurrentUserIncluded && shortProfile) {
      const currentUserRanking = sortedRankings.find(ranking => ranking.partyId === Number(shortProfile.partyId));

      if (currentUserRanking) {
        const award = findTournamentAwardForRanking(tournament, currentUserRanking.ranking);

        const bonusPlanAmountWithCurrency = isDefined(award?.bonusPlan?.bonusAmount)
          ? `${award?.bonusPlan.bonusAmount} SC`
          : isDefined(award?.loyaltyPoints)
            ? `${award?.loyaltyPoints} LP`
            : '-';
        const toWin = award?.bonusPlan?.additionalAward?.amountWithCurrency
          ? `${award?.bonusPlan?.additionalAward?.amountWithCurrency} + ${bonusPlanAmountWithCurrency}`
          : bonusPlanAmountWithCurrency;

        const currentPlayer = {
          rank: currentUserRanking.ranking,
          player: maskString(currentUserRanking.nickname),
          score: `${currentUserRanking?.points ?? '-'}`,
          toWin,
          isCurrentPlace: true
        };

        if (leaderboardItems.length === 10) {
          leaderboardItems[leaderboardItems.length - 1] = currentPlayer;
        }

        if (leaderboardItems.length < 10) {
          leaderboardItems.push(currentPlayer);
        }
      }
    }

    leaderboardItems = leaderboardItems
      .sort((a, b) => a.rank - b.rank)
      .slice(0, 10);

    return leaderboardItems;
  }
);

