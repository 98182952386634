import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import {
  Skeleton,
  Stack,
  Typography
} from '@mui/joy';
import { isEmpty } from '@shared/lib';
import {
  TournamentsListItem,
  selectTournaments,
  useTournamentsSelector,
  selectIsLoading as selectIsTournamentsLoading,
  fetchTournaments,
} from '@entities/tournaments';
import {
  selectIsAuthenticated,
  useSessionSelector
} from '@entities/session';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  MetaTagsBlock
} from '@widgets/meta-tags-block';
import {
  selectIsLoading as selectIsPageLoading,
  selectMetaTagCanonical,
  selectMetaTagDescription,
  selectMetaTagKeywords,
  selectMetaTagTitle,
  selectHeaderText,
  useTournamentsPageSelector,
} from '../model';
import { useGetTournamentsPageQuery } from '../api';
import { TournamentsLayout } from './tournaments-layout.component';
import { NoRacesPlaceholder } from './no-races-placeholder.component';

export const Tournaments: FC = () => {
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'tournaments',
    }
  });

  useGetTournamentsPageQuery();

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const isPageLoading = useTournamentsPageSelector(selectIsPageLoading);
  const keywords = useTournamentsPageSelector(selectMetaTagKeywords);
  const title = useTournamentsPageSelector(selectMetaTagTitle);
  const description = useTournamentsPageSelector(selectMetaTagDescription);
  const canonical = useTournamentsPageSelector(selectMetaTagCanonical);
  const headerText = useTournamentsPageSelector(selectHeaderText);
  const isTournamentsLoading = useTournamentsSelector(selectIsTournamentsLoading);
  const tournaments = useTournamentsSelector(selectTournaments);

  useEffect(() => {
    dispatch(fetchTournaments(isAuthenticated))
  }, [dispatch, isAuthenticated]);

  return !isPageLoading && (
    <>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      <Stack
        sx={({ breakpoints }) => ({
          paddingBlock: '16px 32px',
          paddingInline: 2,
          maxWidth: 1312,
          width: '100%',
          height: '100%',
          [breakpoints.down(491)]: {
            paddingBlock: '8px 24px',
          },
        })}>
        <Typography
          sx={({ palette, breakpoints }) => ({
            marginBlock: 4,
            fontSize: 28,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '36px',
            color: palette.common.white,
            [breakpoints.down(491)]: {
              marginBlock: 2,
              fontSize: 20,
              lineHeight: '30px',
            },
          })}>
          {headerText ?? 'Tournaments'}
        </Typography>

        {isTournamentsLoading && (
          <TournamentsLayout>
            {Array(6).fill(null).map((_, index) => (
              <Skeleton
                key={index}
                variant='rectangular'
                sx={{
                  maxWidth: 410,
                  width: '100%',
                  height: 510,
                  margin: 'auto',
                  borderRadius: 8,
                }}
              />
            ))}
          </TournamentsLayout>
        )}

        {!isEmpty(tournaments) && !isTournamentsLoading && (
          <TournamentsLayout>
            {tournaments.map(tournament => (
              <TournamentsListItem
                key={tournament.name}
                sx={{
                  margin: 'auto',
                  height: '100%',
                  justifyContent: 'space-between',
                }}
                {...tournament}
              />
            ))}
          </TournamentsLayout>
        )}

        {isEmpty(tournaments) && !isTournamentsLoading && (
          <NoRacesPlaceholder />
        )}
      </Stack>
    </>
  );
};
