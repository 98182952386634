import {
  Checkbox as JoyCheckbox,
  CheckboxProps as JoyCheckboxProps,
} from '@mui/joy';
import { FC } from 'react';

export interface CheckboxProps
  extends Pick<
    JoyCheckboxProps,
    'variant' | 'color' | 'sx' |
    'disabled' | 'checked' | 'value' | 'label' |
    'onBlur' | 'onChange'
  > {
  variant?: 'outlined';
  color?: 'primary';
  disabled?: boolean;
  checked?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({ ...props }) => (
  <JoyCheckbox {...props} />
);
